var Popup = {
    isLoadingPopupShown: function()
    {
        return typeof $('#show_loading_popup').data('loading-show') !== 'undefined';
    },
    customPopupHandler: function () {
        if($('#successfullySubscribedPopup').length !== 0) {
            Popup.showCustom($('#successfullySubscribedPopup'));
        }

        if($('#subscription-notification').length !== 0) {
            Popup.showCustom($('#subscription-notification'));
        }

        if($('#voucher-notification-user-blocked').length !== 0) {
            Popup.showCustom($('#voucher-notification-user-blocked'));
        }

        if($('#voucher-notification-applied').length !== 0) {
            Popup.showCustom($('#voucher-notification-applied'));
        }

        if (typeof settingsSaved !== 'undefined' && settingsSaved) {
            Popup.showCustom($('#saveNotificationPopup'));
        }

        if( typeof showNotification !== 'undefined' && showNotification) {
            Popup.showCustom($('#notification'));
        }

        if (typeof systemNotFound !== 'undefined' && systemNotFound) {
            Popup.showCustom($('#popupSystemNotFound'));
        }

        if (typeof contractNotFound !== 'undefined' && contractNotFound) {
            Popup.showCustom($('#popupContractNotFound'));
        }

        if (typeof systemsAdded !== 'undefined') {
            Popup.showCustom($('#systemAddedPopup'));
        }

        if (typeof objectPasswordError !== 'undefined') {
            Popup.showCustom($('#popupObjectPasswordError'));
        }

        if (typeof showModuleOfflinePopup !== 'undefined') {
            $('#infoPopupContent').html(moduleOfflineInfo);
            $('#infoPopup').show();
        }
        if (typeof systemAdded !== 'undefined') {
            Popup.showCustom($('#systemAddSuccessPopup'));
        }
        if (typeof installerAccessGranted !== 'undefined') {
            Popup.showCustom($('#installerAccessGrantedPopup'));
        }
        if ( typeof showMustReReadSystemPopup !== 'undefined') {
            Popup.showCustom($('#mustReReadSystemPopup'));
            $('#doNotRemindChk').change(function() {
                if ($('#doNotRemindChk').is(":checked")) {
                    $('#reReadOkBtn').hide();
                } else {
                    $('#reReadOkBtn').show();
                }
                System.saveDoNotRemind($('#doNotRemindChk').data('system-id'), $('#doNotRemindChk').is(":checked") ? 1 : 0);
            });
        }
    },
    showLoadingPopupMessage: function() {
        var popupContainer = $('#popupLoadingMessage');
        popupContainer.show();

        var maxRetryAttempts = $('#loading_popup_retries').data('loadingRetires');
        var timeInterval =  $('#loading_popup_interval').data('loadingInterval');
        var retryAction =  $('#loading_popup_retry_action').data('loadingRetryAction');
        var successAction =  $('#loading_popup_success_action').data('loadingSuccessAction');
        var errorAction =  $('#loading_popup_error_action').data('loadingErrorAction');
        var token = $('#loading_popup_token').data('loading-post-token');


        Popup.posting = false;
        Popup.postSuccess = false;
        var clearIntervalAndRedirect = function(intervalReference, redirect) {
            clearInterval(intervalReference);
            if(window.location === redirect)
            {
                window.location.reload(true);
            }
            window.location = redirect;
        };

        var progressSolved = false;
        var setProgressBarProgress = function (progress) {
            var progressBarSvg = $('#progress-bar');
            if(progressBarSvg.length === 0) {
                return;
            }

            var progressBar = progressBarSvg.find('path');
            var progressText = progressBarSvg.find('text');

            var value = Math.round((250 * progress) / 100);

            progressBar.attr('stroke-dasharray', value + ',250.2');
            progressText.text(progress + '%');
        };

        var progressFullAt = popupContainer.find('div[data-full-at]');
        if (progressFullAt.length !== 0) {
            var fullAt = progressFullAt.data('full-at');
            var current = 0;
            var progressBarInterval = setInterval(function() {
                if(progressSolved) {
                    clearInterval(progressBarInterval);
                    return false;
                }
                if(current > fullAt) {
                    clearInterval(progressBarInterval);
                    return false;
                }
                var percents = Math.round(current * 100 / fullAt);
                setProgressBarProgress(percents);
                if(percents === 100 && !progressSolved) {
                    window.location = errorAction;
                }
                current++;
            }, 1000);
        }

        var attemptPost = function (intervalReference, prevAttemptNo, maxRetrtAttempts, success, fail){
            if (Popup.posting === true) {
                return prevAttemptNo;
            }
            var attemptsToReturn = prevAttemptNo + 1;
            if (maxRetryAttempts !== '' && maxRetryAttempts <= attemptsToReturn) {
                clearIntervalAndRedirect(intervalReference, fail);
            }
            Popup.posting = true;

            $.ajax({
                url: retryAction,
                method: 'post',
                dataType: 'json',
                data: {
                    _token: token
                },
                success: function (data) {
                    Popup.posting = false;
                    if(data[0] === true)
                    {
                        Popup.postSuccess = true;
                        progressSolved = true;
                        setProgressBarProgress(100);
                        clearIntervalAndRedirect(intervalReference, success);
                    } else if (data[0] === false && maxRetryAttempts === '') {
                        Popup.postSuccess = true;
                        progressSolved = true;
                        setProgressBarProgress(100);
                        clearIntervalAndRedirect(intervalReference, fail);
                    }
                },
                error: function () {
                    Popup.posting = false;
                    if (typeof maxRetryAttempts === 'undefined')
                    {
                        clearIntervalAndRedirect(intervalReference, fail);
                    }
                }
            });
            return attemptsToReturn;
        };
        var attempts = attemptPost(null, 0, maxRetryAttempts, successAction, errorAction);
        var interval = setInterval(function(){
            if (attempts === 1 && !Popup.posting && Popup.postSuccess) {
                clearInterval(interval);
                return;
            }
            attempts = attemptPost(interval, attempts, maxRetryAttempts, successAction, errorAction);
        }, timeInterval *1000);

        $('#cancelLoadingPopup').on('click', function(){
            $('#popupLoadingMessage').hide();
            GeneralUI.showPreloader(true);
            clearIntervalAndRedirect(interval, errorAction);
        });
    },
    systemLoadingPopupHandler: function(){
        // check if it should show the loading popup
        var show = typeof $('#show_loading_popup').data('loading-show') !== 'undefined';
        if(show)
        {
            Popup.showLoadingPopupMessage();
        }

    },
    popupHandler: function () {
        var showPopupLink = $('[data-handlers="showPopup"]');
        var closePopupLink = $('[data-handlers="closePopup"]');
        var closePopupAndOpenLink = $('[data-handles="closePopupAndOpen"]');
        var closePopupAndOpenDownload = $('[data-handlers="closePopupAndOpenDownload"]');
        var closePopupAndSubmitImeiLink = $('[data-handlers="closePopupAndSubmitImei"]');
        var confirmPopup = $('[data-handlers*="confirmPopup"]');

        showPopupLink.on('click', function (e) {
            e.preventDefault();
            var parameter = $(this).data('popup-function');
            if (parameter !== undefined) {
                var dotSeparated = parameter.split('.');
                var f = '';
                if (dotSeparated.length === 2) {
                    var object = window[dotSeparated[0]];
                    f = object[dotSeparated[1]];

                } else {
                    f = window[$(this).data('popup-function')];
                }

                if (typeof f === 'function') {
                    f($(this), $(this).data('popup-id'));
                }
            }
            var pop = $('#' + $(this).data('popup-id'));
            var confirmFunction = $(this).data('confirm-function');
            var param1 = $(this).data('param1');
            var param2 = $(this).data('param2');
            var param3 = $(this).data('param3');
            var confirmationButton = pop.find('[data-handlers*="confirmPopup"]');
            if ( confirmFunction !== undefined ) {
                confirmationButton.data('confirm-function', confirmFunction);
            }
            if ( param1 !== undefined ) {
                confirmationButton.data('param1', param1);
            }
            if ( param2 !== undefined ) {
                confirmationButton.data('param2', param2);
            }
            if ( param3 !== undefined ) {
                confirmationButton.data('param3', param3);
            }

            Popup.show($(this));
        });

        closePopupLink.on('click', function (e) {
            e.preventDefault();
            Popup.close($(this));
        });

        closePopupAndOpenLink.on('click', function () {
            Popup.close($(this));
        });

        closePopupAndOpenDownload.on('click', function(e) {
            e.preventDefault();
            Popup.close($(this));
            var link = $(e.target).prop('href');
            window.open(link, '_blank');
        });

        closePopupAndSubmitImeiLink.on('click', function (e) {
            e.preventDefault();
            Popup.closeAndSubmit($(this), $('#imei-form'));
        });
        
        confirmPopup.on('click', function(e) {
            var parameter = $(this).data('confirm-function');
            if (parameter !== undefined) {
                e.preventDefault();
                var noClose = $(this).data('no-close');
                var dotSeparated = parameter.split('.');
                var f = '';
                if (dotSeparated.length === 2) {
                    var object = window[dotSeparated[0]];
                    f = object[dotSeparated[1]];
                } else {
                    f = window[parameter];
                }

                if (typeof f === 'function') {
                    f($(this));
                }
                if ( noClose === undefined || noClose === false || noClose === '0' || noClose === 0 ) {
                    Popup.close($(this));
                }
            }
        });
    },
    show: function ($this) {
        var popupElement = $('#' + $this.data('popup-id')),
            body = $('body'),
            time = 200;

        body.addClass('popup-visible');
        popupElement.fadeIn(time);
        pauseRefresh();
    },
    showCustom: function (popupElement) {
        var body = $('body'),
            time = 200;

        body.addClass('popup-visible');
        popupElement.fadeIn(time);
    },
    fixPopupPosition: function (popupElement) {
        var viewPortHeight = $(window).height(),
            visible = popupElement.is(':visible'),
            contentContainer = popupElement.find('.popup__content'),
            contentContainerHeight;

        if (!visible) {
            var currentStyle = popupElement.attr('style');
            popupElement.attr('style', 'top: -1010px; height: 1000px; display: block');
            contentContainerHeight = contentContainer.height();
            popupElement.removeAttr('style');
            popupElement.attr('style', currentStyle);
        } else {
            contentContainerHeight = contentContainer.height();
        }

        contentContainer.css('top', Math.round((viewPortHeight - contentContainerHeight) / 2));
    },
    close: function ($this, callback) {
        var popupId = $this.data('popup-id'),
            body = $('body'),
            time = 200;

        $('#' + popupId).fadeOut(time, function () {
            body.removeClass('popup-visible');
            if (callback && typeof callback === "function") {
                callback();
            }
            resumeRefresh();
        });
    },
    closeAndSubmit: function ($this, form) {
        form.submit();
        Popup.close($this);
    },
    licenseHandler: function () {
        var callLicensePopupLink = $('[data-handlers="callLicensePopup"]');
        var hideLicensePopupLink = $('[data-handlers="hideLicensePopup"]');

        callLicensePopupLink.on('click', function (e) {
            e.preventDefault();

            var form = $('form');
            var resendActivation = $('#resend-activation');
            resendActivation.hide();

            $.ajax({
                url: '/register/test',
                data: form.serialize(),
                dataType: 'json',
                method: 'POST',
                success: function (data) {
                    if (data.success) {
                        Popup.callLicensePopup($(this));
                    } else {
                        var link = resendActivation.find('a');
                        link.attr('href', link.attr('href') + '/' + data.token);
                        resendActivation.show();
                    }
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    } else if (jqXHR.status === 422) {
                        GeneralUI.ajaxValidationErrors(jqXHR.responseJSON, $('ul.errors'));
                    }
                }
            });
        });

        hideLicensePopupLink.on('click', function (e) {
            e.preventDefault();
            Popup.hideLicensePopup($(this));
        });
    },
    callLicensePopup: function () {
        var licensePopup = $('[data-handlers="licensePopup"]'),
            frame = $('.frame'),
            time = 200;

        frame.addClass('popup-visible');
        licensePopup.fadeIn(time);
    },
    hideLicensePopup: function () {
        var licensePopup = $('[data-handlers="licensePopup"]'),
            frame = $('.frame'),
            time = 200;

        frame.removeClass('popup-visible');
        licensePopup.fadeOut(time);
    },
    reactPopup: function ($this, popupId) {
        var step1 = $('#' + popupId + ' .popup-step.popup-step-1');
        var step2 = $('#' + popupId + ' .popup-step.popup-step-2');
        var form = $('#' + popupId + ' form');
        var areaNamePlace = $('#' + popupId + ' #react-area-name');
        var areaButton = $('#' + popupId + ' a.select-area');
        var areaId = 0;
        var submitButton = $('#' + popupId + ' #react-submit');
        var errorList = $('#react-error');

        errorList.html('');

        form.off('submit');
        submitButton.off('click');

        form.on('submit', function (e) {
            e.preventDefault();
        });

        areaButton.on('click', function (e) {
            e.preventDefault();
            areaId = $(this).data('area-id');
            var name = $(this).data('area-name');
            areaNamePlace.text(name);

            step1.hide();
            step2.show();
        });

        if (areaButton.length === 1) {
            areaButton.click();
        } else {
            step1.show();
            step2.hide();
        }

        submitButton.on('click', function (e) {
            e.preventDefault();
            var url = form.attr('action') + '/' + areaId;
            errorList.html('');

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (data.success) {
                        Popup.close($this);
                    } else {
                        errorList.append('<li>' + data.error + '</li>');
                    }
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    } else if (jqXHR.status === 422) {
                        GeneralUI.ajaxValidationErrors(jqXHR.responseJSON, errorsUl, '#' + form.attr('id'));
                    }
                }
            });
        });
    },
    sensorEdit: function ($this, popupId) {
        var sensorId = $this.data('sensorid'),
            sensorName = $this.closest('li').find('.sensor-name').text().trim();

        var form = $('#' + popupId + ' form');
        var sensorNameObj = form.find('input[name="name"]');
        var sensorIdObj = form.find('input[name="id"]');
        var errorsUl = form.find('ul#sensor-edit-errors');
        var titleName = $('#sensorEditTitle-name');
        var titleType = $('#sensorEditTitle-type');
        var sensorTypeContainer = $('#sensorTypeContainer');
        var sensorTypeBox = sensorTypeContainer.find('#sensorTypeBox');
        if ( $this.data('edit-type') === 'yes' ) {
            var sensorType = $this.data('type');
            var sensorTypeIndex = '*';
            if ( sensorType === undefined || sensorType === '0' || sensorType === 0 ) {
                sensorTypeIndex = '0';
                sensorType = '*'; // Kadangi zymesim pagal indeksa, tai reiksme turi buti *
            }
            titleName.hide();
            titleType.show();
            sensorNameObj.hide();
            sensorTypeContainer.show();
            sensorTypeBox.data('selected-value', sensorType);
            sensorTypeBox.data('selected-index', sensorTypeIndex);
        } else {
            titleName.show();
            titleType.hide();
            sensorNameObj.show();
            sensorTypeContainer.hide();
            sensorTypeBox.data('selected-value', '*');
            sensorTypeBox.data('selected-index', '*');
        }
        ListboxHandler.select(sensorTypeBox);

        sensorIdObj.val(sensorId);
        sensorNameObj.val(sensorName);

        //Clear previous errors
        errorsUl.html('');
        GeneralUI.validField(sensorNameObj.attr('name'));

        form.off('submit');
        form.on('submit', function (e) {

            var hideForm = function () {
                sensorIdObj.val('');
                sensorNameObj.val('');

                Popup.close($this, function() {
                    Popup.showCustom($('#saveNotificationPopup'));
                });
            };

            e.preventDefault();
            
            var formSensorType = $(this).find('#formSensorType');
            formSensorType.val(sensorTypeBox.data('selected-value'));

            $.ajax({
                url: $(this).attr('action'),
                method: 'post',
                dataType: 'json',
                data: $(this).serialize(),
                success: function (data) {
                    var newSensorName = data.sensor.name;
                    var sensorId = data.sensor.id;
                    var sensorHolder = $('#sensor_holder_' + sensorId);

                    sensorHolder.find('.sensor-name').text(newSensorName);
                    if ( data.units !== undefined ) {
                        sensorHolder.find('.sensorUnitText').text(data.units);
                        sensorHolder.find('.edit-sensor-type').data('type', data.sensor.type);
                        var currentIcon = sensorHolder.find('.list__icon svg');
                        var newIcon = $('#sensorTypeIcon_' + data.sensor.type + ' svg').clone();
                        
                        currentIcon[0].parentNode.insertBefore(newIcon[0], currentIcon[0]);
                        currentIcon[0].remove();
                    }

                    hideForm();
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        //Neautorizuota uzklausa
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    } else if (jqXHR.status === 422) {
                        GeneralUI.ajaxValidationErrors(jqXHR.responseJSON, errorsUl, '#' + form.attr('id'));
                    }
                }
            });
        });
    },
    eventFilter: function() {
        var company = $('#filterCompany option');
        if ( company.length === 0 ) return;
        
        var preselectedReaction = 0;
        if ( typeof gReactionSelection === 'undefined' ) {
            var currentlySelected = $('#filterType option:selected');
            if ( currentlySelected.length > 0 ) {
                preselectedReaction = currentlySelected.val();
                console.log(preselectedReaction);
            }
            gReactionSelection = $('#filterType option').detach();
        
            $('#filterCompany').on('change', function() {
                Popup.eventFilterCompanyChanged();
            });
        }
        Popup.eventFilterCompanyChanged(preselectedReaction);
    },
    eventFilterCompanyChanged: function(selectThis) {
        var company = $('#filterCompany option:selected');
        $('#filterType').empty().append(gReactionSelection.filter('.type_-1'))
                .append(gReactionSelection.filter('.type_' + company.val()));
        if ( selectThis === undefined ) {
            $('#filterType').val(0).trigger('change.select2');
        } else {
            $('#filterType').val(selectThis).trigger('change.select2');
        }
    }
};

var Checkbox = {
    handler: function () {
        var checkboxChangeInput = $('[data-handlers="checkboxChange"]').find('input[type="checkbox"]');

        checkboxChangeInput.each(function () {
            Checkbox.change($(this));
        }).on('change', function () {
            checkboxChange($(this));
        });
        Checkbox.handleBillingCompany();
        Checkbox.handlePgmTypeSelection();
        
        /* Komponentų būsenos valdymas priklausomai nuo checkbox reik�mės */
        var stateControlers = $('.state-controller--checkbox');
        stateControlers.on('change', function() { Checkbox.toggleState($(this)) });
        /* I��aukiam funkciją pirmą kartą, kad nustatytų pradinę būseną */
        stateControlers.each(function() { Checkbox.toggleState($(this)); });
    },
    handleBillingCompany: function () {
        $('#billingCompany').on('click', function(event){
            if ($(event.target).prop('value') === '1') {
                $(event.target).prop('value', '0');
            } else {
                $(event.target).prop('value', '1');
            }
        });
    },
    handlePgmTypeSelection: function () {
        var pulseCheckbox = $('#pulse'),
            levelCheckbox = $('#level'),
            timeInput = $('#time'),
            pgmType = $('#pgm_type');

        levelCheckbox.on('click', function(event){
            levelCheckbox.prop('checked', true);
            timeInput.prop('disabled', true);
            pulseCheckbox.prop('checked', false);
            pgmType.val('level');
        });

        pulseCheckbox.on('click', function(event){
            pulseCheckbox.prop('checked', true);
            timeInput.prop('disabled', false);
            pgmType.val('pulse');
            levelCheckbox.prop('checked', false);
        });
    },
    groupHandler: function () {
        var checkAll = $('.js-checkbox-block #all'),
            checkboxBlock = $('.js-checkbox-group .checkbox-block'),
            checkboxInput = checkboxBlock.find('input:checkbox');

        checkAll.on('change', function () {
            var $this = $(this);
            checkboxInput.prop('checked', $this.prop('checked'));
        });

        checkboxInput.on('change', function () {
            if (checkboxBlock.find('input:checked').length === checkboxInput.length) {
                checkAll.prop('checked', true);
            } else {
                checkAll.prop('checked', false);
            }
        });


        // Checkbox group toggle
        var checkboxToggleBlock = $('[data-handlers="checkboxToggleBlock"]');
        var checkboxToggleGroup = '.js-form-group-toggle',
            checkboxToggle = $('[data-handlers="checkboxToggle"] input:checkbox');


        checkboxToggleBlock.each(function () {
            var $this = $(this),
                $thisCheckbox = $(this).find(checkboxToggle);

            if ($thisCheckbox.is(':checked')) {
                $this.find(checkboxToggleGroup).show();
            }
        });

        checkboxToggle.on('change', function () {
            var $this = $(this),
                time = 200;

            var target = $this.data('target-id') || false;
            var $thisCheckboxToggleGroup;

            if (target === false) {
                $thisCheckboxToggleGroup = $this.parents(checkboxToggleBlock).find(checkboxToggleGroup);
            } else {
                $thisCheckboxToggleGroup = $('#' + target);
            }

            if ($this.is(':checked')) {
                $thisCheckboxToggleGroup.slideDown(time);
            } else {
                $thisCheckboxToggleGroup.slideUp(time);
            }
        });
    },
    change: function ($this) {
        var isChecked = $this.is(':checked'),
            checkboxSlider = $this.parents('[data-handlers="checkboxChange"]');

        if (isChecked) {
            checkboxSlider.addClass('status-slider--on')
                .removeClass('status-slider--off');
        } else {
            checkboxSlider.removeClass('status-slider--on')
                .addClass('status-slider--off');
        }
    },
    toggleState: function ($this) {
        var targetItemClass = $this.data('controllable');
        if ( targetItemClass === null || targetItemClass === '' ) {
            return; // Nėr ką valdyt.
        }
        
        var targetItems = $('.' + targetItemClass);
        if ( targetItems.length === 0 ) return;
        
        var enableOn = $this.data('enable-on');
        if ( enableOn !== 'checked' && enableOn !== 'unchecked' ) {
            enableOn = 'checked';
        }
        
        var innerCheck = $this.find('input[type="checkbox"]');
        var componentState = ((enableOn === 'checked') && innerCheck.is(':checked'));
        if ( !componentState ) componentState = ((enableOn === 'unchecked') && !innerCheck.is(':checked'));
        
        var actionType = $this.data('perform-action');
        if ( componentState ) {
            if ( actionType === undefined || actionType === 'state' ) {
                targetItems.removeAttr('disabled');
            } else if ( actionType.startsWith('slide') ) {
                if ( actionType === 'slideD' ) {
                    targetItems.slideDown(200);
                } else {
                    targetItems.slideUp(200);
                }
            }
        } else {
            if ( actionType === undefined || actionType === 'state' ) {
                targetItems.attr('disabled', true);
            } else if ( actionType.startsWith('slide') ) {
                if ( actionType === 'slideD' ) {
                    targetItems.slideUp(200);
                } else {
                    targetItems.slideDown(200);
                }
            }
        }
    }
};

var Tutorial = {
    handler: function () {
        var prevSlideLink = $('[data-handlers="prevSlide"]');
        var nextSlideLink = $('[data-handlers="nextSlide"]');

        prevSlideLink.on('click', function (e) {
            e.preventDefault();
            Tutorial.prevSlide($(this));
        });

        nextSlideLink.on('click', function (e) {
            e.preventDefault();
            Tutorial.nextSlide($(this));
        });
    },
    prevSlide: function ($this) {
        $this.parents('.carousel__leaf').removeClass('active').addClass('next')
            .prev().addClass('active').removeClass('previous')
            .prev().addClass('previous');

        $('.carousel__paging').find('.active').removeClass('active')
            .prev().addClass('active');
    },
    nextSlide: function ($this) {
        $this.parents('.carousel__leaf').removeClass('active').addClass('previous')
            .next().addClass('active').removeClass('previous next');

        $('.carousel__paging').find('.active').removeClass('active')
            .next().addClass('active');
    }
};

var Mobile = {
    iOsHandler: function () {
        var isOnIOS = navigator.userAgent.match(/iPhone/i);
        if (isOnIOS) {
            $('.ios-preload').on('click', function (e) {
                // Jeigu tai formos submit mygtukas, tai jam butina prideti is-submit, kitaip negerai veiks.
                var isFormSubmit = $(this).data('is-submit');
                // will not prevent default if it's a submit button with data-is-submit
                if ( typeof(suppressPreloader) === 'undefined' || suppressPreloader <= 0 ) {
                    GeneralUI.showPreloader();
                }

                var tempHref = $(this).attr('href');
                setTimeout(function () {
                    if (typeof isFormSubmit === 'undefined') {
                        e.preventDefault();
                        window.location = tempHref;
                    }
                }, 0);
            });

            $('[data-handlers="back"]').on('tap click', function () {
                GeneralUI.hidePreloader();
            });
        } else {

            window.onbeforeunload = function () {
                if ( typeof handleDeviceUsers !== 'undefined' && handleDeviceUsers ) {
                    var hasUnsavedChanges = $('.user_mod-yes');
                    if ( hasUnsavedChanges.length > 0 ) {
                        return 'Changes may not be saved if you leave this page.';
                    }
                }
                
                if ( typeof(suppressPreloader) === 'undefined' || suppressPreloader <= 0 ) {
                    GeneralUI.showPreloader();
                } else {
                }
            };
        }
    },
    isMobile : function ()
    {
        var ua = navigator.userAgent;
        if (!!ua) {
            if (ua.match(/iPhone/i) || ua.match(/iPad/i) || ua.match(/Android/i)){ return true; }
        }

        return false;
    },
    pullToRefreshHandler: function () {
        var pullOptions = {
            callback: function () {
                location.reload();
            },
            pullThreshold: 120,
            spinnerTimeout: 1000
        };
        $('.mobile .content').xpull(pullOptions);
    },
    refreshButtonHandler: function() {        
        // Refresh button
        $('[data-handlers="refresh"]').on('tap click', function (e) {
            location.reload();
            e.preventDefault();
        });
    }
};

var Area = {
    menuHandler: function () {
        var showAreaMenuLink = $('[data-handlers="showAreaMenu"]');
        var hideAreaMenuLink = $('[data-handlers="hideAreaMenu"]');

        showAreaMenuLink.on('click', function (e) {
            e.preventDefault();
            Area.showMenu($(this));
        });

        hideAreaMenuLink.on('click', function (e) {
            e.preventDefault();
            Area.hideMenu();
        });
        
        var forgetPins = $('[data-handlers="forgetAreaPin"]');
        forgetPins.on('click', function (e) {
            e.preventDefault();
            Area.forgetPin($(e.target));
        });
    },
    showMenu: function ($this) {
        var time = 200;
        $('.area__menu').fadeOut(time);
        $this.next('.area__menu').fadeIn(time);
        $('.area__overlay').show();
    },
    hideMenu: function () {
        var time = 200;
        $('.area__menu').fadeOut(time);
        $('.area__overlay').hide();
    },
    changeStatus: function ($this) {
        var pgmId = $this.data('pgmid'),
            pgmState = $this.attr('data-pgm-state'),
            token = $('#' + $this.data('popup-id') + ' form input[name="_token"]').val(),
            action = $this.data('formAction');
        var isEnabled = $this.data('enabled');
        if ( isEnabled !== undefined && !isEnabled ) {
            return;
        }

        var errorsUlObj = $('ul#pgm-status-errors');
        errorsUlObj.html('');

        var data = {
            '_token': token,
            'id': pgmId,
            'state': pgmState == '1' ? 0 : 1
        };

        $.ajax({
            url: action,
            method: 'post',
            dataType: 'json',
            data: data,
            success: function (data) {
                if (!data.success) {
                    GeneralUI.ajaxValidationErrors(data.errors, errorsUlObj);
                }
                
                var that = $this;    
                if ( data.success ) {
                    $this.attr('data-pgm-state', data.pgm.on);
                    $this.attr('data-pgm-timer', data.pgm.pulse_timer);
                    if ( $this.data('type') === 'switch' ) {
                        if ( data.pgm.on === 1 ) {
                            $('#switch_' + data.pgm.id + '_on').show();
                            $('#switch_' + data.pgm.id + '_off').hide();
                        } else {
                            $('#switch_' + data.pgm.id + '_on').hide();
                            $('#switch_' + data.pgm.id + '_off').show();
                        }
                    } else {
                        if (data.pgm.on === 1) {
                            $this.removeClass('status-slider--off')
                                .addClass('status-slider--on');
                        } else {
                            $this.removeClass('status-slider--on')
                                .addClass('status-slider--off');
                        }
                    }

                    if(data.pgm.type === 'pulse' && data.pgm.on === 1)
                    {
                        var timer = $('#pgm_timer_' + data.pgm.id);
                        timer.text(Pgm.convertSecondsToTimerString(data.pgm.pulse_timer));
                        if ( !timer.is(':visible') ) {
                            timer.show();
                        }
                        var timerInterval = setInterval(function(){
                            data.pgm.pulse_timer --;
                            timer.text(Pgm.convertSecondsToTimerString(data.pgm.pulse_timer));
                            if (data.pgm.pulse_timer <= 0) {
                                that.attr('data-pgm-state', 0);
                                if ( that.data('type') === 'switch' ) {
                                    $('#switch_' + data.pgm.id + '_on').hide();
                                    $('#switch_' + data.pgm.id + '_off').show();
                                } else {
                                    that.removeClass('status-slider--on')
                                        .addClass('status-slider--off');
                                }
                                timer.hide();
                                clearInterval(timerInterval);
                            }
                        }, 1000);
                    }

                    if (data.pgm.type === 'pulse' && data.pgm.on === 0) {
                        data.pgm.pulse_timer = 0;
                    }        
                }

                if ( data.pgms === false ) {
                    return;
                } else {
                }
                
                if ( $this.data('type') === 'switch' ) {
                    $.each(data.pgms, function(iIndex, iPgm) {
                        var iSwitch = $('#switch_' + iPgm.id + '_on').parent();
                        iSwitch.attr('data-pgm-state', iPgm.on);
                        if ( iPgm.on === 1 ) {
                            $('#switch_' + iPgm.id + '_on').show();
                            $('#switch_' + iPgm.id + '_off').hide();
                        } else {
                            $('#switch_' + iPgm.id + '_on').hide();
                            $('#switch_' + iPgm.id + '_off').show();
                        }
                    });
                } else {
                    $.each(data.pgms, function(iIndex, iPgm) {
                        var element = $('#pgm_slider_' + iPgm.id);
                        if ( element.length !== 0 ) {
                            element.attr('data-pgm-state', iPgm.on);
                            if (iPgm.on === 1) {
                                element.removeClass('status-slider--off')
                                    .addClass('status-slider--on');
                            } else {
                                element.removeClass('status-slider--on')
                                    .addClass('status-slider--off');
                            }
                        }
                    });
                }
            },
            error: function (jqXHR) {
                if (jqXHR.status === 403) {
                    GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                } else if (jqXHR.status === 422) {
                    GeneralUI.ajaxValidationErrors(jqXHR.responseJSON, errorsUlObj);
                }
            }
        });
    },
    changeStatusSliderHandler: function () {
        var changeStatusLink = $('[data-handlers="changeStatus"]');

        changeStatusLink.on('click', function (e) {
            e.preventDefault();
            Area.changeStatus($(this));
        });
    },
    stateEdit: function ($this, popupId) {
        var areaId = $this.data('areaId'),
            areaName = $this.data('areaName'),
            areaState = $this.data('current-status'),
            rememberPin = $this.data('remember-pin'),
            pin = $this.data('pin'),
            areaCurrentState = $this.data('area-interpretated-status'),
            popup = $('#' + popupId),
            form = popup.find('form');
        var areaAsPgmNoPass = $this.data('areaAsPgmNoPass');
        var hasAreaAsPgm = $this.data('hasAreaAsPgm');
        var formAction = $this.data('statusChangeAction');
        var pinField = form.find('input[name="pin"]');
        var codeInput = $('[data-handlers="keypadCode"]');
        var keyList = form.find('#keyList');
        var bypassAction = $this.data('bypassAction');
        var allowedAreaStatuses = $this.data('area-statuses');
        form.attr('action', formAction);
        rememberPin = toBool(rememberPin);
        var areaStates = form.find('.area__state');
        areaStates.show();
        $.each(areaStates, function(i, iItem) {
            if ( areaAsPgmNoPass === false && !rememberPin ) {
                $(iItem).addClass('btn-change-popup-step');
                $(iItem).data('submit', "0");
                $(iItem).data('step', "2");
            } else {
                $(iItem).removeClass('btn-change-popup-step');
                $(iItem).data('submit', "1");
                $(iItem).data('step', "");
            }
        });
        
        pinField.removeAttr('readonly');
        if ( hasAreaAsPgm ) {
            form.find('#header-pin').hide();
            form.find('#header-pass').show();
            pinField.removeAttr('pattern');
            keyList.hide();
        } else {
            form.find('#header-pin').show();
            form.find('#header-pass').hide();
            pinField.attr('pattern', "[0-9]*");
            codeInput.val('');
            keyList.show();
            if ($(pinField).data('mobile') === 1) {
                pinField.attr('readonly', 'readonly');
            }
        }

        form.find('#bypass-failure-msg').hide();
        form.find('input[name="area_id"]').val(areaId);
        form.find('input[name="current_state"]').val(areaCurrentState);
        pinField.val('');
        form.on('submit', function (e) {
            e.preventDefault();
        });

        //Restore popup steps
        popup.find('.popup-step').hide();
        popup.find('.popup-step.popup-step-1').show();
        popup.find('#remember_pin').prop('checked', false);
        popup.find('.area__status-name').text(areaName);
        popup.find('.area__state-' + areaState).hide();
        if (areaState === 'arm') {
            popup.find('.area__state:not(.area__state-off)').hide();
            popup.find('.area__state-off').show();
        } else {
            if ( hasAreaAsPgm ) { // Valdant su PGM nera busenu Sleep ir Stay
                popup.find('.area__state').hide();
                popup.find('.area__state-arm').show();
                if ( areaState !== 'off' ) {                    
                    popup.find('.area__state-off').show();
                }
            } else {
                if ( areaState === 'unknown' || areaState === 'off' ) { // Jei busena nezinoma arba isjungta, rodom Arm, Stay, Sleep
                    popup.find('.area__state:not(.area__state-' + areaState + ')').show();
                } else { // Jeigu busena Arm, Stay arba Sleep, tada rodom tik Off
                    popup.find('.area__state').hide();
                    popup.find('.area__state-off').show();
                }
            }
        }
        if ( allowedAreaStatuses.arm === undefined ) {
            popup.find('.area__state-arm').hide();
        }
        if ( allowedAreaStatuses.stay === undefined ) {
            popup.find('.area__state-stay').hide();
        }
        if ( allowedAreaStatuses.sleep === undefined ) {
            popup.find('.area__state-sleep').hide();
        } else {
            popup.find('.area__state-sleep').text(allowedAreaStatuses.sleep);
        }
        if ( allowedAreaStatuses.off === undefined ) {
            popup.find('.area__state-off').hide();
        }

        if ( rememberPin ) {
            popup.find('#remember_pin').prop('checked', true);
            form.find('input[name="pin"]').val(pin);
        }

        popup.find('.area__state').off('click');

        var stateToSet;
        var autoSubmit = 0;
        popup.find('.area__state').on('click', function (e) {
            e.preventDefault();

            stateToSet = $(this).data('areaState');
            form.find('input[name="state"]').val(stateToSet);

            autoSubmit = parseInt($(this).data('submit'));

            if (autoSubmit === 1) {
                popup.find('#change-area-status-button').click();
            }

        });

        //Change popup step.
        var changeStepBtn = popup.find('.btn.btn-change-popup-step');
        changeStepBtn.on('click', function () {
            var step = $(this).data('step');

            popup.find('.popup-step').hide();
            popup.find('.popup-step.popup-step-' + step).show();
            popup.find('#pin').focus();
        });

        //Submit form.
        var submitButton = popup.find('#change-area-status-button');
        submitButton.off('click');
        submitButton.on('click', function (e) {
            var currentPin = form.find('input[name="pin"]').val();
            var dataToSend = form.serialize();
            var formAction = form.attr('action');
            e.preventDefault();
            GeneralUI.showPreloader(true);
            $.ajax({
                url: formAction,
                method: 'post',
                dataType: 'json',
                data: dataToSend,
                success: function (data) {
                    if (data.wrongPin) {
                        var infoPop = $('#infoPopup');
                        infoPop.find('#infoPopupContent').html(data.pinError);
                        Popup.close($this, function() {
                            Popup.show(infoPop);
                        });
                    } else if (data.g10 && !data.status) { 
                        var infoPop = $('#infoPopup');
                        infoPop.find('#infoPopupContent').html(data.g10InfoText);
                        Popup.close($this, function() { 
                            Popup.show(infoPop);
                        });
                    } else if (data.status) {
                        var pinCheck = popup.find('#remember_pin');
                        if ( pinCheck.is(':checked') ) {
                            var forgetPinItem = $('[data-handlers="forgetAreaPin"][data-area-id="' + $this.data('areaId') + '"]');
                            forgetPinItem.show();
                            
                            $this.data('remember-pin', true);
                            $this.data('pin', popup.find('input[name="pin"]').val());
                        }
                        Popup.close($this, function() {
                            if (data.forceReloadUrl) {
                                //top.location.href = data.forceReloadUrl;
                            }
                        });
                    } else if ( data.failedZones.length === 0 ) {
                        var infoPop = $('#infoPopup');
                        infoPop.find('#infoPopupContent').html(data.otherError);
                        Popup.close($this, function() { 
                            Popup.show(infoPop);
                        });
                    } else {
                        dataToSend.previousUrl = formAction;
                        Zone.bypassRender( {
                            zones:  data.failedZones,
                            area:   data.area,
                            autoSubmit: true,
                            autoSubmitData: dataToSend,
                            submitButton: submitButton,
                            usePin: true,
                            pin: currentPin,
                            formAction: bypassAction
                        } );
                        var systemId = $this.data('systemId');
                        Popup.close($this, function() {
                            Popup.show($('#areaBypassZonesPopup'));
                        });
                    }
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    } else if (jqXHR.status === 422) {
                        var errors = jqXHR.responseJSON;
                        GeneralUI._displayValidationErrors(errors);
                    }
                }
            });
        });
    },
    nameEdit: function ($this, popupId) {
        var areaId = $this.data('areaId'),
            areaName = $this.data('areaName'),
            areaNo = $this.data('areaNo'),
            areaNoDisabled = $this.data('areaNoDisabled'),
            form = $('#areaNameEditPopup form');
        var formAction = $this.data('name-change-action');
        
        $(form).attr('action', formAction);

        var fieldNameObj = form.find('input[name="area_name"]'),
            fieldIdObj = form.find('input[name="area_id"]'),
            fieldQueueNoObj = form.find('input[name="queue_id"]');

        fieldNameObj.val(areaName);
        fieldIdObj.val(areaId);
        fieldQueueNoObj.val(areaNo);
        fieldQueueNoObj.prop('disabled', areaNoDisabled);

        var errorsUl = form.find('ul#area-edit-errors');

        //Clear previous errors
        errorsUl.html('');
        GeneralUI.validField(fieldNameObj.attr('name'));
        GeneralUI.validField(fieldIdObj.attr('name'));

        // unbind previous submit handlers
        form.off('submit');

        // bind form submit handler
        form.on('submit', function (e) {
            e.preventDefault();

            $.ajax({
                url: $(this).attr('action'),
                method: 'post',
                dataType: 'json',
                data: $(this).serialize(),
                success: function (data) {
                    $this.parents('.list__areas-item').find('.area__name-text').text(data.area.name);
                    $this.data('areaName', data.area.name);
                    $this.data('areaNo', data.area.queue_no);
                    Popup.close($this, function() {
                        Popup.showCustom($('#saveNotificationPopup'));
                    });
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    } else if (jqXHR.status === 422) {
                        GeneralUI.ajaxValidationErrors(jqXHR.responseJSON, errorsUl, '#' + form.attr('id'));
                    }
                }
            });
        });

        $('.area__menu, .area__overlay').hide();
    },
    confirmDelete: function ($this, popupId) {
        var popup = $('#' + popupId),
            confirmDeleteHref = $this.attr('href'),
            confirmDeleteButton = popup.find('[data-handlers*="confirmDeleteButton"]');
        var me = $this;
        confirmDeleteButton.off('click');
        confirmDeleteButton.on('click', function () {

            $.ajax({
                url: confirmDeleteHref,
                method: 'POST',
                dataType: 'json',
                data: {
                    _token: me.data('token')
                },

                success: function (data) {
                    Popup.close($(me));
                    $('.area__menu').hide();
                    $($.find('[data-unique-area-id="' + data['areaId'] + '"]')[0]).remove();
                    if ( Mobile.isMobile() ) {
                        if ( data.areaCount !== undefined && data.areaCount === 0 ) {
                            window.location.reload();
                        }
                    }
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        //Neautorizuota uzklausa
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    }
                }
            });
        });
    },
    forgetPin: function ($this) {
        var action = $this.attr('href');
        ajaxPostWithToken(action, 
        function( result ) { //success
            if ( result.success ) {
                $this.hide();
                
                var areaItem = $('.area__status-' + $this.data('area-id'));
                areaItem.data('remember-pin', false);
                areaItem.data('pin', '');
            } else {
                console.log(result.error);
            }
        },
        function ( result ) { // error
            console.log(result);
        },
        $this.data('token'));
    }
};

var Navbar = {
    isOpen : false,
    handler: function () {
        var navbarToggle = $('[data-handlers="navbarToggle"]');

        navbarToggle.on('click', function (e) {
            e.preventDefault();
            Navbar.call();
        });
    },
    call: function () {
        var sideMenu = $('[data-handlers="sideMenu"]');
        sideMenu.addClass('opened');
        Navbar.isOpen = true;
    },
};

var SideMenu = {
    handler: function () {
        var sideMenuOverlay = $('[data-handlers="sideMenuOverlay"]');

        sideMenuOverlay.on('click', function (e) {
            e.preventDefault();
            SideMenu.hide();
        });
        if (Mobile.isMobile()) {
            $('body').swipe({
                swipeLeft: SideMenu.swipeHandler,
                swipeRight: SideMenu.swipeHandler,
                allowPageScroll: 'vertical'
            });
        }
    },
    hide: function () {
        var sideMenu = $('[data-handlers="sideMenu"]');
        sideMenu.removeClass('opened');
        Navbar.isOpen = false;
    },
    swipeHandler: function (event, direction) {
        var targetIsGood = $(event.target).hasClass('container-fluid') || Navbar.isOpen || $(event.target).attr('id') === 'contentFrame';
        if ( typeof preventSideMenuSwipe === 'undefined' || !preventSideMenuSwipe || targetIsGood) {
            if (direction === 'right') {
                Navbar.call();
            } else if (direction === 'left') {
                SideMenu.hide();
            }
        }
    }
};

var Zone = {
    add: function ($this, popupId) {
        var form = $('#zoneAddNewPopup form');
        var errorsUl = form.find('ul#zone-add-validation-errors');

        //Clear previous errors
        errorsUl.html('');
        GeneralUI.validField(form.find('#queue_no').attr('name'));
        GeneralUI.validField(form.find('#name').attr('name'));

        form.on('submit', function (e) {
            e.preventDefault();

            $.ajax({
                url: form.attr('action'),
                method: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    window.location.reload();
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    } else if (jqXHR.status === 422) {
                        GeneralUI.ajaxValidationErrors(jqXHR.responseJSON, errorsUl, '#' + form.attr('id'));
                    }
                }
            });
        });
    },
    edit: function ($this, popupId) {
        var zoneId = $this.data('zoneId'),
            zoneNameObj = $this.parents('.pr-table__row').find('.list__title .sensor-name'),
            zoneQueueNoObj = $this.parents('.pr-table__row').find('.list__title .list__sub-title'),
            zoneName = zoneNameObj.text(),
            queueNo = $this.data('zone-number'),
            form = $('#zoneEditPopup form');

        var fieldId = form.find('input[name="id"]').val(zoneId),
            fieldNameObj = form.find('input[name="name"]'),
            fieldQueueNoObj = form.find('input[name="queue_no"]');

        var errorsUl = form.find('ul#zone-validation-errors');

        //Clear previous errors and set initial values.
        fieldNameObj.val(zoneName);
        fieldQueueNoObj.val(queueNo);
        errorsUl.html('');
        GeneralUI.validField(fieldNameObj.attr('name'));
        GeneralUI.validField(fieldQueueNoObj.attr('name'));

        form.off('submit');

        form.on('submit', function (e) {
            e.preventDefault();

            $.ajax({
                url: form.attr('action'),
                method: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    zoneNameObj.text(data.zone.name);
                    zoneQueueNoObj.text(data.zone.queue_no);
                    if ( zoneId === 0 ) {
                        $this.data('zone-id', data.zone.id);
                        var li = $this.parent().parent().parent(); // div.area__menu -> div.list__menu -> li
                        if ( li[0].tagName === 'LI' ) {
                            li.data('zoneid', data.zone.id);
                        }
                    }
                    Popup.close($this, function() {
                        Popup.showCustom($('#saveNotificationPopup'));
                    });
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    } else if (jqXHR.status === 422) {
                        GeneralUI.ajaxValidationErrors(
                            jqXHR.responseJSON,
                            form.find('ul#zone-validation-errors'), '#' + form.attr('id')
                        );
                    }
                }
            });
        });
    },
    /**
     * Bypass failed zones form.
     *
     * @param zones
     * @param area
     * @param previousData
     */
    bypassRender: function (bypassData) {
        var $this = $('#areaBypassZonesPopup');
        var form = $this.find('form');
        form.attr('action', bypassData.formAction);
        form.off('submit');
        form.on('submit', function (e) {
            e.preventDefault();
        });

        var codeInput = form.find('[data-handlers="keypadCode"]');
        codeInput.val('');

        var step1 = $this.find('.popup-step-1');
        var step2 = $this.find('.popup-step-2');

        step2.find('#pin-bypass').val('');
        step1.show();
        step2.hide();

        var template = form.find('#control-group-template');
        var zonesContainer = form.find('#failed-zones');
        zonesContainer.html('');

        for (var key in bypassData.zones) {
            var zone = bypassData.zones[key];

            var clone = template.clone();
            clone.removeAttr('id');
            clone.find('input').attr({'name': 'zone[' + key + ']', 'id': 'zone' + key + ''});
            clone.find('label').attr({'for': 'zone' + key + ''}).text(zone);
            clone.show();

            zonesContainer.append(clone);
        }

        form.find('#areaStateToCheckZoneBypass').val(bypassData.area.currentStatus);
        form.find('#bypass_area_id').val(bypassData.area.id);

        var buttonBypass = form.find('#btn-bypass-zones');
        buttonBypass.off('click');
        buttonBypass.on('click', function (e) {
            e.preventDefault();
            if ( bypassData.usePin ) {
                codeInput.val(bypassData.pin);
                step2.find('#pin-bypass').val(bypassData.pin);
                form.find('#btn-bypass-zones-final').click();
            } else {
                step1.hide();
                step2.show();
                step2.find('#pin-bypass').val('').focus();
            }
        });

        var buttonFinalBypass = form.find('#btn-bypass-zones-final');
        buttonFinalBypass.off('click');
        buttonFinalBypass.on('click', function (e) {
            e.preventDefault();
            GeneralUI.showPreloader(true);
            $.ajax({
                url: form.attr('action'),
                method: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    Popup.close($this, function() {
                        if ( bypassData.autoSubmit ) {
                            bypassData.submitButton.click();
                        } else {
                            var statusAgain = $('.area__status-' + data.area.id);
                            if ( statusAgain.length === 0 ) {
                                statusAgain = $('#area-' + data.area.id);
                            }
                            if ( statusAgain.length === 0 ) {
                                // kodel nerado?
                            } else {
                                statusAgain.click();
                            }
                        }

                        if (!data.success) {
                            $('#bypass-failure-msg').show();
                        }
                    });
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    } else if (jqXHR.status === 422) {
                        var errors = jqXHR.responseJSON;
                        GeneralUI._displayValidationErrors(errors);
                    }
                }
            });
        });
    },
    setForBypass: function($this) {
        var zoneNumber = $this.data('zone-no');
        var isBypassed = $this.data('bypassed');
        var zoneInput = $('#zoneForBypass');
        var bypassInput = $('#bypassStatus');
        
        zoneInput.val(zoneNumber);
        bypassInput.val(isBypassed);
        
        showKeypad($this);
    },
    setForBypassD: function($this) {
        var form = $($this.closest('form'));
        form.find('.zoneForBypassEdt').val($this.data('zoneNo'));
        form.find('.bypassStatusEdt').val($this.data('bypassed'));
        
        var keypad = $('#keypadPopup');
        var submitButton = keypad.find('button[type="submit"]');
        submitButton.off('click');
        submitButton.on('click', function() {
            form.find('.bypassPinEdt').val(keypad.find('#pin').val());
            form.find('.bypassPinRememberEdt').val(keypad.find('#remember_pin').is(':checked'));
            form.submit();
        });
        var pinField = keypad.find('#pin');
        pinField.off('keypress');
        pinField.on('keypress', function(e) { // Dashboard'e popup nera formos ribose, todel neveikia submit paspaudus ENTER.
            if ( e.which === 13 ) {
                submitButton.click();
            }
        });
        showKeypad($this);
    }
};

var DatePicker = {
    handler: function () {
        $('.datepicker')
            .datepicker({
                autoclose: true,
                format: 'yyyy-mm-dd',
                weekStart: 1//,
                //language: "lt"
            }).on('hide', function () {
            $('.datepicker-ranges').find('.btn-primary').removeClass('btn-primary').addClass('btn-default');
        });

        var setDateRangeLink = $('[data-handlers="setRange"]');

        setDateRangeLink.on('click', function (e) {
            e.preventDefault();
            DatePicker.setRange($(this), $(this).data('range'));
        });
    },
    setRange: function ($this, range) {
        var dateTo = $('#dateTo'),
            dateFrom = $('#dateFrom');

        dateTo.datepicker('setDate', 'today');
        switch (range) {
            case 'day':
                dateFrom.datepicker('setDate', '-1d');
                break;
            case 'week':
                dateFrom.datepicker('setDate', '-1w');
                break;
            case 'month':
                dateFrom.datepicker('setDate', '-1m');
                break;
            default:
                break;
        }

        $this.removeClass('btn-default').addClass('btn-primary')
            .parent().siblings().find('.btn-primary').removeClass('btn-primary').addClass('btn-default');
    }
};

var PreloaderHandle;
var SlowConnectionTextHandler;

var GeneralUI = {
    customSelectHandler: function () {
        if (!Mobile.isMobile() && typeof useNativeSelect === 'undefined') {
            $('.select2').select2({
                minimumResultsForSearch: -1
            });
            $('.select2-data').select2({
                minimumInputLength: 0
            });
            $('.select2-ajax').select2({
                minimumInputLength: 3,
                ajax: {
                    //Skip event listener for ajax start
                    global: false,
                    url: function () {
                        return $(this).data('href');
                    },
                    type: 'json',
                    method: 'POST',
                    data: function (params) {
                        return {
                            search: params.term,
                            _token: $(this).data('token')
                        };
                    }
                }
            });
            // removing above/below selection
            var selects = $('.select2,.select2-ajax,.select2-data').data('select2');
            if ( typeof selects !== 'undefined' ) {

                selects.on('open', function (e) {
                    this.dropdown._positionDropdown = function(){};
                });
            }
        }
    },
    niceScrollHandler: function () {
        var niceScroll = $('[data-handlers*="niceScroll"]');
        niceScroll.scrollbar();
    },

    /**
     * Show preloader.
     *
     * @return void
     */
    showPreloader: function (force) {
        if (force === undefined) {
            force = false;
        }
        if (!Popup.isLoadingPopupShown() || force) {
            if ( force ) {
                $('#preloader').show();
            } else {
                PreloaderHandle = setTimeout(function() {
                    $('#preloader').show();
                    
                    SlowConnectionTextHandler = setTimeout(function() {
                        $('#slow_connection_text').show();
                    }, 10000);
                }, 500);
            }
        }
    },

    /**
     * Hide preloader.
     *
     * @return void
     */
    hidePreloader: function () {
        clearTimeout(PreloaderHandle);
        clearTimeout(SlowConnectionTextHandler);
        if(!Popup.isLoadingPopupShown()) {
            $('#preloader').hide();
        }
        $('#slow_connection_text').hide();
    },
    /**
     * Ajax switchers.
     * selector: a.status-slider.ajaxSwitch
     * must have:
     *      data-errors - selector to errors list.
     *      href        - action to send request to.
     *      data-token  - laravel token to go through csrf validations.
     *
     * On success switch state is changed.
     * On error ul is filled with error message.
     */
    ajaxSwitcherHandler: function () {
        var ajaxSwitch = $('a.status-slider.ajaxSwitch');
        ajaxSwitch.on('click', function (e) {
            e.preventDefault();
            var newState = $(this).hasClass('status-slider--on') ? 0 : 1;
            var errorsUl = $($(this).data('errors'));
            errorsUl.html('');

            var me = $(this);

            $.ajax({
                url: me.attr('href'),
                data: {
                    state: newState,
                    _token: me.data('token')
                },
                dataType: 'json',
                method: 'POST',
                success: function (data) {
                    if (data.state) {
                        me.removeClass('status-slider--off').addClass('status-slider--on');
                    } else {
                        me.removeClass('status-slider--on').addClass('status-slider--off');
                    }
                },
                error: function (jqXHR) {
                    GeneralUI.ajaxValidationErrors(jqXHR.responseJSON, errorsUl);
                }
            });
        });
    },
    confirmDelete: function ($this, popupId) {
        var popup = $('#' + popupId);
        var confirmDeleteHref = $this.attr('href');
        var confirmDeleteButton = popup.find('[data-handlers*="confirmDeleteButton"]');
        confirmDeleteButton.attr('href', confirmDeleteHref);
    },
    /**
     * Displays validation errors
     *
     * @param errors
     * @returns {string}
     * @private
     */
    _displayValidationErrors: function (errors) {
        var message = '';
        for (var key in errors) {
            message += key + ': ';
            if (errors.hasOwnProperty(key)) {
                message += errors[key];
            }

            message += '<br>';
        }
        $('#infoPopupContent').html(message);
        $('#infoPopup').show();
        return true;
    },
    confirmLogout: function ($this, popupId) {
        var popup = $('#' + popupId),
            confirmLogoutHref = $this.data('action'),
            confirmLogoutButton = popup.find('[data-handlers="confirmLogoutButton"]');
        
        confirmLogoutButton.attr('disabled', true);
        GG.signedIn(
            function() { // Yes
                confirmLogoutButton.removeAttr('disabled');
                confirmLogoutButton.attr('href', '#');
                confirmLogoutButton.off('click');
                confirmLogoutButton.on('click', function() {
                    GG.logOut(confirmLogoutHref);
                });
            },
            function() { // No
                confirmLogoutButton.removeAttr('disabled');
                confirmLogoutButton.attr('href', confirmLogoutHref);
            }
        );
    },
    /**
     * Parse errors from ajax request, place them in list, add classes for inputs.
     *
     * @param errors            errors list from ajax request
     * @param errorUljQueryObj  selector to ul element to append error messages to
     * @param formId            [optional] if page has several inputs with same name, it is possible to specify form id.
     *
     * @return void
     */
    ajaxValidationErrors: function (errors, errorUljQueryObj, formId) {
        var errorsInfo = GeneralUI.parseErrors(errors);

        var formId = formId || '';
        var i;
        var fields = errorsInfo.fields;
        var messages = errorsInfo.errors;

        errorUljQueryObj.html('');
        for (i = 0; i < messages.length; i++) {
            errorUljQueryObj.append('<li>' + messages[i] + '</li>');
        }

        for (i = 0; i < fields.length; i++) {
            GeneralUI.invalidField(fields[i], formId);
        }
    },
    /**
     * Parse errors object from ajax request and form new object with two arrays: first contains field names that
     * has errors, second one contains all error messages.
     *
     * @param errors
     *
     * @returns {{fields: Array, errors: Array}}
     */
    parseErrors: function (errors) {
        var fields = [];
        var errorMessages = [];

        for (var field in errors) {
            fields.push(field);

            var i;
            var fieldErrors = errors[field];

            for (i = 0; i < fieldErrors.length; i++) {
                errorMessages.push(fieldErrors[i]);
            }
        }

        return {
            fields: fields,
            errors: errorMessages
        };
    },
    refreshDateHandler: function () {
        window.onload = function () {

            d = new Date();
            $('#refresh_date').html(d.toLocaleString());
        };
    },
    /**
     * Remove 'has-error' class from field's form group container.
     *
     * @param fieldName
     * @param formId    [optional]
     *
     * @return void
     */
    validField: function (fieldName, formId) {
        var formGroup = GeneralUI.getFieldGroupByFieldName(fieldName, formId);

        if (formGroup) {
            formGroup.removeClass('has-error');
        }
    },
    /**
     * Set 'has-error' class on field's form group container.
     *
     * @param fieldName
     * @param formId    [optional]
     *
     * @return void
     */
    invalidField: function (fieldName, formId) {
        var formGroup = GeneralUI.getFieldGroupByFieldName(fieldName, formId);
        if (formGroup) {
            formGroup.addClass('has-error');
        }
    },
    /**
     * Get form group container by field name and optionally form id.
     *
     * @param fieldName
     * @param formId    [optional]
     *
     * @returns jQueryObj
     */
    getFieldGroupByFieldName: function (fieldName, formId) {
        var formId = formId || '';
        var formGroup = $(formId + ' [name="' + fieldName + '"]').closest('div.form-group');

        return formGroup.length > 0 ? formGroup : false;
    },
    keypadHandler: function () {
// Keypad numbers click
        var ua = navigator.userAgent,
            clickEvent = (ua.match(/iPhone/i) || ua.match(/iPad/i)) ? 'touchstart' : 'click';
        var keypadKeyLink = $('[data-handlers="keypadKey"]');

        keypadKeyLink.on(clickEvent, function (e) {
            e.preventDefault();
            GeneralUI.keypadKey($(this));
        });

        // Keypad input change (for desktop)
        var keypadCodeInput = $('[data-handlers="keypadCode"]');
        keypadCodeInput.keydown(function (event) {
            // Allow special chars + arrows
            var attr = keypadCodeInput.attr('pattern');
            if (typeof attr === 'undefined' || attr === false || attr === '' ) {
                return;
            }
            if (event.keyCode === 46 || event.keyCode === 8 || event.keyCode === 9
                || event.keyCode === 27 || event.keyCode === 13
                || (event.keyCode === 65 && event.ctrlKey === true)
                || (event.keyCode >= 35 && event.keyCode <= 39)) {
                return;
            } else {
                // If it's not a number stop the keypress
                if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
                    event.preventDefault();
                }
            }
        });
    },
    keypadKey: function ($this) {
        var value = $this.data('key-val'),
            codeInput = $('[data-handlers="keypadCode"]');

        if (value === -1) {
            codeInput.val(function (index, value) {
                return value.substr(0, value.length - 1);
            });
        } else {
            codeInput.val(codeInput.val() + value);
        }
    }
};

var Pgm = {
    nameEdit: function ($this, popupId) {
        var pgmId = $this.data('pgmId'),
            pgmName = $this.data('pgmName'),
            pgmType = $this.data('pgmType'),
            pgmTime = $this.data('pgmTime'),
            form = $('#pgmNameEditPopup form');

        var fieldNameObj = form.find('input[name="name"]'),
            fieldIdObj = form.find('input[name="id"]'),
            fieldTypeObj = form.find('input[name="pgm_type"]'),
            fieldLevelCheckbox = form.find('input[name="level"]'),
            fieldPulseCheckbox = form.find('input[name="pulse"]'),
            fieldTimeObj = form.find('input[name="time"]');

        var errorsUl = form.find('ul#pgm-edit-errors');
        var iconNumber = $this.data('icon');

        //Clear previous errors and set initial data
        fieldIdObj.val(pgmId);
        fieldNameObj.val(pgmName);
        fieldTypeObj.val(pgmType);
        if (pgmType === 'pulse') {
            fieldLevelCheckbox.prop('checked', false);
            fieldPulseCheckbox.prop('checked', true);
            fieldTimeObj.prop('disabled', false);
        } else {
            fieldLevelCheckbox.prop('checked', true);
            fieldPulseCheckbox.prop('checked', false);
            fieldTimeObj.prop('disabled', true);
        }
        fieldTimeObj.val(pgmTime);
        errorsUl.html('');
        GeneralUI.validField(fieldNameObj.attr('id'));
        GeneralUI.validField(fieldIdObj.attr('id'));

        if ( iconNumber !== undefined ) {
            var pop = $('#' + popupId);
            pop.find('#pgm_iconSet').val(iconNumber);
            pop.find('#switch_id').val($this.data('switch'));
            var icons = pop.find('.switch__icon-set');
            $.each(icons, function(i, o) {
                if ( $(o).data('number') === iconNumber ) {
                    $(o).show();
                    var iconSet = $('#selectedIconSet_' + iconNumber);
                    if ( iconSet.length > 0 ) {
                        iconSet.prop('checked', true);
                    }
                    var iconPop = $('#switchIconPicker');
                    iconPop.find('[type="submit"]').data('parent-popup', popupId);
                } else {
                    $(o).hide();
                }
            });
        }
        
        // unbind previous submit handlers
        form.off('submit');

        // bind form submit handler
        form.on('submit', function (e) {
            e.preventDefault();

            $.ajax({
                url: $(this).attr('action'),
                method: 'post',
                dataType: 'json',
                data: $(this).serialize(),
                success: function (data) {
                    $('#pgm_holder_' + pgmId).find('.list__title .h1').text(data.pgm.name);
                    $('#pgm_holder_' + pgmId).find('.area__name-text').text(data.pgm.name);
                    $this.data('pgmName', data.pgm.name);
                    $this.data('pgmType', data.pgm.type);
                    $this.data('pgmTime', data.pgm.pulse_time_in_seconds);
                    if ( data.switch !== undefined && data.switch !== null ) {
                        $this.data('icon', data.pgm.icon_number);
                        var iconOn = $('#switch_' + data.pgm.id + '_on svg');
                        var iconOff = $('#switch_' + data.pgm.id + '_off svg');
                        var newIconSet = $('#switchIconSet_' + data.pgm.icon_number + '_picker');
                        var newIconSetOn = newIconSet.find('.switch__icon--on svg').clone();
                        var newIconSetOff = newIconSet.find('.switch__icon--off svg').clone();
                        
                        iconOn[0].parentNode.insertBefore(newIconSetOn[0], iconOn[0]);
                        iconOff[0].parentNode.insertBefore(newIconSetOff[0], iconOff[0]);
                        iconOn[0].remove();
                        iconOff[0].remove();
                    }
                    Popup.close($this, function() {
                        Popup.showCustom($('#saveNotificationPopup'));
                    });
                },
                error: function (jqXHR) {
                    if (jqXHR.status === 403) {
                        GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                    } else if (jqXHR.status === 422) {
                        GeneralUI.ajaxValidationErrors(jqXHR.responseJSON, errorsUl, '#' + form.attr('id'));
                    }
                }
            });
        });
    },
    handlePgmTimers: function () {
        if (typeof pgmTimers !== 'undefined') {
            $.each(pgmTimers, function (key, pgmTimer){
                var interval = setInterval(function(){
                    pgmTimer.timer --;
                    var timer = $('#pgm_timer_' + pgmTimer.id)
                    timer.text(Pgm.convertSecondsToTimerString(pgmTimer.timer));
                    if ( !timer.is(':visible') ) {
                        timer.show();
                    }
                    if (pgmTimer.timer <= 0) {
                        var pgmButton = $('#pgm_holder_' + pgmTimer.id).find('a[data-pgm-id="' + pgmTimer.id + '"]');
                        if ( pgmButton.data('switch') !== undefined && pgmButton.data('switch') !== '' ) {
                            var startTime = pgmButton.data('starting-time');
                            console.log(startTime);
                            if ( startTime !== '' ) {
                                $('#switch_' + pgmTimer.id + '_on').hide();
                                $('#switch_' + pgmTimer.id + '_off').show();                             
                            }
                        } else {
                            $('#pgm_slider_' + pgmTimer.id).removeClass('status-slider--on')
                                .addClass('status-slider--off');
                        }
                        timer.hide();
                        clearInterval(interval);
                    }
                }, 1000)
            });
        }
    },
    convertSecondsToTimerString: function (time) {
        var minutes = Math.floor(time / 60);
        var seconds = time - minutes * 60;
        if (minutes < 10)
        {
           minutes = '0' + minutes;
        }

        if (seconds < 10) {
            seconds = '0' + seconds;
        }

        return minutes + ':' + seconds;
    },
    listenToPgmAsAreaCheckboxes: function() {
        var checks = $('.pgm_as_area_checkbox');
        checks.on('change', Pgm.handlePgmAsAreaCheckboxes);
    },
    handlePgmAsAreaCheckboxes: function() {
        var checks = $('.pgm_as_area_checkbox:checkbox:checked');
        if ( checks.length > 0 ) {
            $('#pulse-interval').prop('disabled', false);
        } else {
            $('#pulse-interval').prop('disabled', true);       
        }
    },
    setIconSet: function($this) {
        var iconPop = $('#' + $this.data('popup-id'));
        var checkedIcon = iconPop.find('.icon-set-radio:checked');
        if ( checkedIcon.length > 0 ) {
            var parent = $('#' + $this.data('parent-popup'));
            parent.find('#pgm_iconSet').val(checkedIcon.val());
            var iconSets = parent.find('.switch__icon-set');
            $.each(iconSets, function(i, o) {
                if ( $(o).data('number') === parseInt(checkedIcon.val()) ) {
                    $(o).show();
                } else {
                    $(o).hide();
                }
            });
        }
    }
};

var Settings = {
    homePageHandler: function () {
        var pageDropDown = $('#edit-home-page-settings #page');
        pageDropDown.on('change', function () {
            var actionDropDown = $('#edit-home-page-settings #action');
            if ($(this).val() > 0) {
                actionDropDown.removeAttr('disabled');
                actionDropDown.parent('.form-group').show();
            } else {
                actionDropDown.attr('disabled', 'disabled');
                actionDropDown.parent('.form-group').hide();
            }
        });
    },
    globalHandler: function () {
        var viewUserCheckbox = $('#main_user_enable_view_users'),
            editUserCheckbox = $('#main_user_enable_edit_users');

        viewUserCheckbox.change(function () {
            var checked = viewUserCheckbox.prop('checked');
            editUserCheckbox.prop('disabled', !checked);
        });
    }
};

var System = {
    pickerHandler: function () {
        var systemPickerLink = $('[data-handlers="systemPickerLink"]');

        systemPickerLink.on('click', function (e) {
            e.preventDefault();
            System.toggleSystemList($(this));
        });
    },
    toggleSystemList: function ($this) {
        var systemPickerList = $('[data-handlers="systemPickerList"]'),
            time = 200;

        $this.toggleClass('opened');
        if ($this.hasClass('system-picker__action')) {
            $this.parent().find('.system-picker__link').toggleClass('opened');
        }
        systemPickerList.stop(false, true).slideToggle(time);
    },
    testIpcomConnectionHandler: function () {
        var ipComTestButton = $('a.test-ip-com');
        ipComTestButton.on('click', function (e) {
            var self = $(this);
            var showStatus = function (status) {
                var formGroup = self.closest('.form-group');
                var failure = formGroup.find('.test-ip-com-result .failure');
                var success = formGroup.find('.test-ip-com-result .success');

                if (status) {
                    formGroup.removeClass('has-error');
                    failure.hide();
                    success.show();
                } else {
                    formGroup.addClass('has-error');
                    failure.show();
                    success.hide();
                }
            };

            e.preventDefault();
            var url = self.data('href');
            var token = self.data('token') || false;
            if (token === false) {
                data = self.closest('form').serialize();
            } else {
                data = {_token: token};
            }

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: data,
                success: function (data) {
                    showStatus(data.success);
                },
                error: function () {
                    showStatus(false);
                }
            });
        });
    },
    editHandler: function () {
        var pgmAsAreaCk = $('.pgm_as_area_checkbox'),
            directControlCk = $('#enable_direct_control');

        pgmAsAreaCk.each(function (i, element) {
            if ($(element).prop('checked') === true) {
                directControlCk.prop('disabled', true);
            }
        });

        if (directControlCk.prop('checked')) {
            pgmAsAreaCk.prop('disabled', true);
        }

        pgmAsAreaCk.change(function () {
            var anyChecked = false;
            pgmAsAreaCk.each(function (i, element) {
                if ($(element).prop('checked') === true) {
                    anyChecked = true;
                }
            });

            directControlCk.prop('disabled', anyChecked);
        });

        directControlCk.change(function () {
            var checked = directControlCk.prop('checked');
            pgmAsAreaCk.prop('disabled', checked);
        });
    },
    signUpFormLanguageHandler: function () {
        var signUpLanguage = $('.auth form #language');
        signUpLanguage.on('change', function () {
            var formData = $('.auth form').serializeArray();
            var dataToSubmit = {};

            for (var i in formData) {
                var input = formData[i];
                if (input.name !== 'password' && input.name !== 'password_confirmation') {
                    dataToSubmit[input.name] = input.value;
                }
            }

            var url = $(this).data('url');

            $.ajax({
                url: url,
                data: dataToSubmit,
                dataType: 'json',
                method: 'POST',
                success: function () {
                    window.location.reload();
                },
                error: function () {
                    window.location.reload();
                }
            });
        });
    },
    saveDoNotRemind: function(system_id, value) {
        $.ajax({
            url: '/system/' + system_id + '/set/no_remind/' + value,
            method: 'GET',
            error: function (jqXHR) {
                if (jqXHR.status === 403) {
                    GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                } else if (jqXHR.status === 422) {
                    GeneralUI.ajaxValidationErrors(jqXHR.responseJSON, $('ul.errors'));
                }
            }
        });
    }
};

var SystemTransfer = {
    transferInputHandler: function () {
        var transferUserSelector = $('[data-handlers="transferUserSelector"]');
        var transferEmailField = $('[data-handlers="transferUserField"]');

        transferUserSelector.on('click', function (e) {
            e.preventDefault();
            SystemTransfer.toggleSelectedUser($(this));
        });

        $(transferEmailField).keyup(SystemTransfer.toggleTransferButtonStateOnFill);
        $(transferEmailField).focusout(SystemTransfer.toggleTransferButtonStateOnFill);
    },
    toggleSelectedUser: function ($this) {
        var radio = $this.find("input[type='radio']");

        if ($this.hasClass('list__selected-user')) {
            $this.removeClass('list__selected-user');
        } else {
            $this.addClass('list__selected-user').siblings().removeClass('list__selected-user');
        }

        if (radio.prop('checked') === '' || (typeof radio.prop('checked') === 'boolean' && !radio.prop('checked'))) {
            radio.prop('checked', 'true');
        } else {
            radio.prop('checked', '');
        }
        SystemTransfer.toggleTransferButtonStateOnSelect(radio);
    },
    toggleTransferButtonStateOnSelect: function (radio) {
        var transferEmailField = $('[data-handlers="transferUserField"]');
        var transferSubmit = $('.transfer');

        if (radio.prop('checked') === '' || (typeof radio.prop('checked') === 'boolean' && !radio.prop('checked'))) {
            transferSubmit.prop('disabled', true);
        } else {
            transferSubmit.prop('disabled', '');
            transferEmailField.val('');
        }
    },
    toggleTransferButtonStateOnFill: function () {
        var transferEmailField = $('[data-handlers="transferUserField"]');
        var transferSubmit = $('.transfer');
        var transferUserSelector = $('[data-handlers="transferUserSelector"]');

        if ($(transferEmailField).val() === '') {
            transferSubmit.prop('disabled', true);
        } else {
            transferSubmit.prop('disabled', '');

            var radio = transferUserSelector.find("input[type='radio']");
            transferUserSelector.removeClass('list__selected-user');
            radio.prop('checked', '');
        }
    }
};

var Dashboard = {
    activeElement : null,
    canEditName : false,
    canEditColor : false,
    canPerformAction: false,
    canPerformActionEx: false,
    init: function() {
        // Dashboard related items.
        var dri = $('[data-handlers="Dashboard"]');
        $.each(dri, function(iIndex, oItem) {
            $(oItem).on('click', function(e) {
                e.preventDefault();
                var func = $(oItem).data('function');
                if ( func === 'addItem' ) {
                    Dashboard.addItem(oItem);
                } else if ( func === 'removeItem' ) {
                    Dashboard.removeItem(oItem);
                }
            });
        });
        var di = $('.dashboard-item');
        if ( di.length > 0 ) {
            di.data('position', $(di[0]).offset().left);
        }
        var areaStatusButtons = $('.area-status-mini-button');
        $.each(areaStatusButtons, function(i, o) {
            o = $(o);
            o.on('click', function(e) {
                var areaId = o.parent().data('area');
                var areaLink = $('#area-' + areaId);
                Area.stateEdit(areaLink, areaLink.data('popup-id'));
                var action = o.data('action');
                var stateChanger = $('.area__state-' + action);
                if ( stateChanger.length > 0 ) {
                    stateChanger.click();                    
                    if ( $(e.target).data('no-cancel') !== '1' || (areaLink.data('areaAsPgmNoPass') === false && !toBool(areaLink.data('remember-pin'))) ) {
                        Popup.show(areaLink);
                    }
                }
            });
        });
        Dashboard.fetchDashboardItems();
    },
    addItem: function(target) {
        var href = $(target).attr('href');
        ajaxPostWithToken(href, 
            function(result) {
                if ( result.success ) {
                    $(target).find('.icon_fa_add').removeClass('icon_fa_add').addClass('icon_fa_remove');
                    $(target).data('function', 'removeItem');
                    $(target).attr('href', result.href);
                    $(target).attr('title', result.title);
                } else {
                    //console.log('Failed to add item to dashboard: ' + result.error);
                }
            },
            function() {
                //console.log('Failed to add item to dashboard.');
            },
            $(target).data('token')
        );
    },
    removeItem: function(target) {
        var href = $(target).attr('href');
        ajaxPostWithToken(href, 
            function(result) {
                if ( result.success ) {
                    $(target).find('.icon_fa_remove').removeClass('icon_fa_remove').addClass('icon_fa_add');
                    $(target).data('function', 'addItem');
                    $(target).attr('href', result.href);
                    $(target).attr('title', result.title);
                } else {
                    //console.log('Failed to remove item from dashboard: ' + result.error);
                }
            },
            function() {
                //console.log('Failed to remove item from dashboard.');
            },
            $(target).data('token')
        );
    },
    fetchDashboardItems: function() {
        var items = $('.dashboard-item');
        if ( items.length === 0 ) return;
        
        var fetchables = [];
        $.each(items, function(iIndex, iItem) {
            var systemNumber = $(iItem).data('system');
            if ( systemNumber === undefined ) {
                return true;
            }
            var found = fetchables.find(function(iSystem) { return systemNumber === iSystem; });
            if ( found === undefined ) {
                fetchables.push(systemNumber);
            }
        });
        var token = $('[name="_token"]');
        if ( token.length === 0 ) {
            return;
        }
        
        suppressPreloader = 0;
        $.each(fetchables, function(iIndex, iItem) {
            var dashboardItems = [];
            $.each(items, function(i, iInnerItem) {
                if ( $(iInnerItem).data('system') === iItem ) {
                    dashboardItems.push($(iInnerItem).data('item'));
                }
            });
            var dataToSend = {
                '_token' : token.val(),
                'system' : iItem,
                'type' : 'main',
                'items' : dashboardItems
            };
            suppressPreloader += 1;
            ajaxPostWithData('/dashboard/fetch/' + iItem, Dashboard.dataFetchSuccess, Dashboard.dataFetchFailure, dataToSend);            
        });
    },
    dataFetchSuccess: function(data) {
        if ( data.success ) {
            var temperatureRequests = [];
            $.each(data.items, function(i, iItem) {
                if ( !iItem.success ) {
                    var failedItem = $('#dashboard-item-' + iItem.id);
                    if ( failedItem.length !== 0 ) {
                        $(failedItem).find('.dashboard-item__loader').hide();
                        $(failedItem).find('.dashboard-item__error').show();
                        if ( typeof iItem.error !== 'undefined' ) {
                            $(failedItem).find('.dashboard-item__error').attr('title', iItem.error);
                        } else {
                            $(failedItem).find('.dashboard-item__error').attr('title', data.error);
                        }
                    }
                } else {
                    var goodItem = $('#dashboard-item-' + iItem.id);
                    if ( goodItem.length !== 0 ) {
                        $(goodItem).find('.dashboard-item__loader svg').hide();
                        if ( iItem.area !== undefined ) {
                            var areaItem = $(goodItem).find('#area-' + iItem.area.id);
                            areaItem.data('areaState', iItem.area.status);
                            areaItem.data('areaActualState', iItem.area.actualStatus);
                            areaItem.data('areaInterpretatedStatus', iItem.area.outputStatus);
                            areaItem.data('currentStatus', iItem.area.statusJs);
                            $('#area_id_' + iItem.area.id + '_0').hide();
                            $('#area_id_' + iItem.area.id + '_1').hide();
                            $('#area_id_' + iItem.area.id + '_2').hide();
                            $('#area_id_' + iItem.area.id + '_2_alarm').hide();
                            $('#area_id_' + iItem.area.id + '_3').hide();
                            $('#area_id_' + iItem.area.id + '_3_alarm').hide();
                            $('#area_id_' + iItem.area.id + '_4').hide();
                            $('#area_id_' + iItem.area.id + '_4_alarm').hide();
                            $('#area_id_' + iItem.area.id + '_5').hide();
                            if ( iItem.area.alarm_in_memory === true || iItem.area.alarm_in_memory === "1" ) {
                                var oStatusIcon = $('#area_id_' + iItem.area.id + '_' + iItem.area.status + '_alarm');
                                if ( oStatusIcon.length === 0 ) { // State does not support alarm status
                                    $('#area_id_' + iItem.area.id + '_' + iItem.area.status).show();
                                } else {
                                    oStatusIcon.show();
                                }
                            } else {
                                $('#area_id_' + iItem.area.id + '_' + iItem.area.status).show();
                            }
                            Dashboard.handleAreaStatusChange(iItem.area.id, iItem.area.status);
                        } else if ( iItem.pgm !== undefined ) {
                            var pgmItem = $(goodItem).find('#pgm_slider_' + iItem.pgm.id);
                            if ( iItem.pgm.on === 1 ) {
                                pgmItem.removeClass('status-slider--off');
                                pgmItem.addClass('status-slider--on');
                            } else {
                                pgmItem.removeClass('status-slider--on');
                                pgmItem.addClass('status-slider--off');                                
                            }
                            pgmItem.data('pgmState', iItem.pgm.on);
                            pgmItem.data('enabled', iItem.pgm.enabled);
                        } else if ( iItem.switch !== undefined ) {
                            var switchItem = $(goodItem).find('#pgm_slider_' + iItem.switch.pgm.id);
                            var iconOn = $('#switch_' + iItem.switch.pgm.id + '_on');
                            var iconOff = $('#switch_' + iItem.switch.pgm.id + '_off');
                            if ( iItem.switch.pgm.on === 1 ) {
                                iconOn.show();
                                iconOff.hide();
                            } else {
                                iconOff.show();
                                iconOn.hide();
                            }
                            switchItem.data('pgmState', iItem.switch.pgm.on);
                            switchItem.data('enabled', iItem.switch.pgm.enabled);
                        } else if ( iItem.sensor !== undefined ) {
                            var sensorItem = $(goodItem).find('#sensor_' + iItem.sensor.id + ' span');
                            if ( iItem.sensor.hasValue || !iItem.sensor.active) {
                                sensorItem.text(iItem.sensor.temperature);
                            } 
                            if ( !iItem.sensor.hasValue){
                                iItem.system = data.system;
                                temperatureRequests.push(iItem);
                            }
                            if ( iItem.sensor.enabled ) {
                                sensorItem.removeClass('btn-default');
                                sensorItem.addClass('btn-primary');
                            } else {
                                sensorItem.removeClass('btn-primary');
                                sensorItem.addClass('btn-default');
                            }
                            if ( iItem.sensor.warn ) {
                                sensorItem.addClass('btn-danger');
                            }
                        } else if ( iItem.zone !== undefined ) {
                            var zoneStatusUndefined = $(goodItem).find('#zone-status-undefined_' + iItem.zone.id);
                            var zoneStatusBypass = $(goodItem).find('#zone-status-bypass_' + iItem.zone.id);
                            var zoneStatusFail = $(goodItem).find('#zone-status-fail_' + iItem.zone.id);
                            var zoneStatusAlarm = $(goodItem).find('#zone-status-alarm_' + iItem.zone.id);
                            var zoneStatusText = $(goodItem).find('#zoneStatus_' + iItem.zone.id);                            
                            if ( iItem.zone.status_translation === '' ) {
                                zoneStatusText.text('');
                            } else {
                                zoneStatusText.text('( ' + iItem.zone.status_translation + ' )');
                            }
                            zoneStatusUndefined.hide();
                            if ( !iItem.zone.enabled || (!iItem.zone.failure && !iItem.zone.bypass && !iItem.zone.alarm) ) {
                                zoneStatusUndefined.show();
                            } else if ( iItem.zone.bypass ) {
                                zoneStatusBypass.show();
                            } else if ( iItem.zone.failure ) {
                                zoneStatusFail.show();
                            } else if ( iItem.zone.alarm ) {
                                zoneStatusAlarm.show();
                            }
                            
                            if ( iItem.zone.bypass ) {
                                $(goodItem).find('.zone-do-bypass-text').hide();
                                $(goodItem).find('.zone-un-bypass-text').show();
                                var unBypassAllowed = $(goodItem).find('.canZoneByUnbypassed');
                                if ( unBypassAllowed.length !== 0 ) {
                                    $(goodItem).addClass('no-swipe');
                                }
                                $(goodItem).find('#zone_bypass_' + iItem.zone.id).data('bypassed', true);
                            } else {
                                $(goodItem).find('.zone-do-bypass-text').show();
                                $(goodItem).find('.zone-un-bypass-text').hide();
                                $(goodItem).find('#zone_bypass_' + iItem.zone.id).data('bypassed', false);
                            }
                            if ( $(goodItem).find('.zoneRequiresPin').length === 0 ) {
                                var a = $(goodItem).find('#zone_bypass_' + iItem.zone.id);
                                a.attr('href', a.data('formAction') + '?zone=' + iItem.zone.queue_no + '&bypassed=' + iItem.zone.bypass);
                            }
                        }
                    }
                }
            });
            
            if ( temperatureRequests.length > 0 ) {
                var itemsToFetch = [];
                $.each(temperatureRequests, function(i, iItem) {
                    var dashboardItem = $('#dashboard-item-' + iItem.id);
                    $(dashboardItem).find('.dashboard-item__loader svg').show();
                    itemsToFetch.push(iItem.id);
                });
                var token = $('[name="_token"]');
                var systemId = temperatureRequests[0].system;
                var dataToSend = {
                    _token : token.val(),
                    system : systemId,
                    type : 'temp',
                    items : itemsToFetch
                };
                suppressPreloader += 1;
                ajaxPostWithData('/dashboard/fetch/' + systemId, Dashboard.dataFetchSuccess, Dashboard.dataFetchFailure, dataToSend); 
            }
        } else {
            var dashboardItems = $('.dashboard-item[data-system=' + data.system + ']');
            var loaders = $(dashboardItems).find('.dashboard-item__loader');
            loaders.hide();
            var errors = $(dashboardItems).find('.dashboard-item__error');
            errors.attr('title', data.error);
            errors.show();
        }
        suppressPreloader -= 1;
    },
    dataFetchFailure: function(data) {
        suppressPreloader -= 1;
        var dashboardItems = $('.dashboard-item[data-system=' + data.system + ']');
        var loaders = $(dashboardItems).find('.dashboard-item__loader');
        loaders.hide();
        var errors = $(dashboardItems).find('.dashboard-item__error');
        errors.show();
    },
    saveReorder: function(e) {
        var dashboardItems = $('.dashboard-item');
        var action = $('#dashboard-list').data('saveReorderAction');
        var token = $('[name="_token"]');
        var order = '';
        $.each(dashboardItems, function(i, iItem) {
            var id = $(iItem).data('item');
            if ( id !== '' ) order += (id + ';');
        });
        var data = {
            _token : token.val(),
            order_of_items : order
        };
        suppressPreloader++; 
        ajaxPostWithData(action, Dashboard.saveSuccess, Dashboard.saveFailed, data);
    },
    saveSuccess: function(data) {
        if ( !data.success ) {
            //console.log('Failed to set new order for dashboard items. Error: ' + data.error);
        }
        suppressPreloader--;
    },
    saveFailed: function(data) {
        //console.log('Failed to set new order for dashboard items.');
        suppressPreloader--;
    },
    _hideLeftButtons: function($container) {
        var hideLeftButtons = $container.find('.swipe-ex-icon-container[data-show="true"]');
        var lastOne = hideLeftButtons.last();
        $.each(hideLeftButtons, function(i, o) {
            if ( lastOne.length > 0 && (lastOne[0] !== o) && $(o).is(':visible') ) {
                $(o).fadeOut();
            }
        });
    },
    _hideRightButtons: function($container) {
        var hideRightButtons = $container.find('.swipe-ex-icon-container[data-show="true"]');
        var firstOne = hideRightButtons.first();
        $.each(hideRightButtons, function(i, o) {
            if ( firstOne.length > 0 && (firstOne[0] !== o) && $(o).is(':visible') ) {
                $(o).fadeOut();
            }
        });
    },
    _showAllButtons: function($container) {
        var showButtons = $container.find('.swipe-ex-icon-container[data-show="true"]');
        $.each(showButtons, function(i, o) {
            if ( !$(o).is(':visible') ) {
                $(o).fadeIn();
            }
        });
    },
    _animate: function($container, percent, duration, durationMs, direction) {
        $container.data('animation-in-progress', true);
        $container[0].style[SwipeHelper.transitionJSPropertyName] = SwipeHelper.transformCSSPropertyName + ' ' + duration + 's ease-out';
        $container[0].style[SwipeHelper.transformJSPropertyName] = 'translate(' + (direction < 0 ? '-' : '') + percent + '%,0) ' + SwipeHelper.hwLayerMagicStyle;
        setTimeout(function(){
            $container[0].style[SwipeHelper.transitionJSPropertyName] = '';
            $container.data('animation-in-progress', false);
        }, durationMs+1);      
    },
    _setStepMoving: function($container, step1, step2) {
        $container.data('step1-moving', step1);
        $container.data('step2-moving', step2);
    },
    _showDeletionArea: function() {
        var deletionArea = $('.dashboard-item__delete-container');
        deletionArea.fadeIn();
    },
    _hideDeletionArea: function() {
        var deletionArea = $('.dashboard-item__delete-container');
        deletionArea.hide();
    },
    handleSwipe: function(event) {
        var actionItemLeft = Dashboard.activeElement.find('.dashboard-item__leftAction');
        var actionItemRight = Dashboard.activeElement.find('.dashboard-item__rightAction');
        var canPerformActionEx = actionItemLeft.data('ex') === true;
        var xMovement = Math.abs(event.detail.x);
        if ( canPerformActionEx ) {
            var liElement = actionItemLeft.parent();
            var w = actionItemLeft.width();
            var n = event.detail.x < 0 ? -1 : 1;
            liElement.data('direction', n);
            var canPerformActionExAt = w * 0.1;
            var step1At = Mobile.isMobile() ? w * 0.3 : w * 0.2;
            var step2At = Mobile.isMobile() ? w * 0.75 : w * 0.55;
            var step2full = w * 0.85;
            var isStep1 = liElement.data('step1') === true;
            var currentOffset = liElement.offset().left;
            var originalPosition = liElement.data('position');
            if ( !isStep1 ) {
                if ( xMovement >= step1At && xMovement < step2At) {
                    if ( liElement.data('step2-moving') === true ) {
                        Dashboard._showAllButtons(liElement);
                        Dashboard._animate(liElement, '50', '0.3', 300, n);
                        event.preventDefault();
                    }
                    Dashboard._setStepMoving(liElement, true, false);
                } else if ( xMovement >= step2At ) {
                    Dashboard._setStepMoving(liElement, false, true);
                    if ( liElement.data('animation-in-progress') !== true && Math.abs(currentOffset - originalPosition) < step2full) {
                        Dashboard._hideLeftButtons(actionItemLeft);
                        Dashboard._hideRightButtons(actionItemRight);
                        Dashboard._animate(liElement, '90', '0.5', 500, n);
                        event.preventDefault();
                        return;
                    } else {
                        event.preventDefault();
                        return;
                    }
                } else {                    
                    Dashboard._setStepMoving(liElement, false, false);
                }
            } else {
                var movingRightOverLimit = (xMovement >= canPerformActionExAt) && (parseInt(liElement.css('left')) > 0) && (n > 0);
                var movingLeftOverLimiut = (xMovement >= canPerformActionExAt) && (parseInt(liElement.css('left')) < 0) && (n < 0);
                if ( movingLeftOverLimiut || movingRightOverLimit ) {
                    Dashboard._setStepMoving(liElement, false, true);
                    if ( liElement.data('animation-in-progress') !== true ) {
                        Dashboard._hideLeftButtons(actionItemLeft);
                        Dashboard._hideRightButtons(actionItemRight);
                        Dashboard._animate(liElement, '40', '0.5', 500, n);
                        event.preventDefault();
                        return;
                    } else {
                        event.preventDefault();
                        return;
                    }
                } else {
                    Dashboard._setStepMoving(liElement, liElement.data('step1-moving'), false);
                    Dashboard._showAllButtons(liElement);
                }
            }
        } else {            
            var w = actionItemLeft.width();
            var wLi = Dashboard.activeElement.width();
            w = Math.min(w, wLi);
            var canPerformActionAt = w * 0.7;
            w = w * 0.8;
            if ( xMovement >= w ) {
                if ( event.detail.x < 0 ) {
                    event.detail.x = w * -1;
                } else {
                    event.detail.x = w;
                }
            }
            
            var check = null;
            if ( event.detail.x < 0 ) {
                check = actionItemRight.find('.fa-check');
            } else {
                check = actionItemLeft.find('.fa-check');
            }
            if ( xMovement >= canPerformActionAt ) {
                if ( check.length > 0 && !check.is(':visible') ) {
                    check.fadeIn();
                    Dashboard.canPerformAction = true;
                }
            } else if ( check.length > 0 && check.is(':visible') ) {
                check.fadeOut();
                Dashboard.canPerformAction = false;
            }
        }
    },
    handleSwipeComplete: function(event) {
        var found = false;
        var liElement = event.target;
        while (!found) {
            if ( liElement.tagName === 'LI' ) {
                found = true;
                break;
            }
            liElement = liElement.parentNode;
        }
        if ( found ) {
            liElement = $(liElement);
            var leftSide = liElement.find('.dashboard-item__leftAction');
            var rightSide = liElement.find('.dashboard-item__rightAction');
            Dashboard.activeElement.find('.fa-check').fadeOut();
            var movedLeftOverLimit = liElement.data('step1') === true && parseInt(liElement.css('left')) > 0 && liElement.data('direction') < 0;
            var movedRightOverLimit = liElement.data('step1') === true && parseInt(liElement.css('left')) < 0 && liElement.data('direction') > 0;
            if ( liElement.data('step1-moving') === true ) {
                Dashboard._setStepMoving(liElement, false, liElement.data('step2-moving'));
                liElement.data('step1', true);
                var direction = liElement.data('direction');
                if ( direction < 0 ) {
                    liElement.css('left', '-' + liElement.width() / 2 + 'px');
                    leftSide.hide();
                } else {
                    liElement.css('left', liElement.width() / 2 + 'px');
                    rightSide.hide();
                }
                liElement[0].style[SwipeHelper.transformJSPropertyName] = '';
                event.detail.noAnimation = true;
                event.preventDefault();
            } else if ( liElement.data('step2-moving') === true || movedLeftOverLimit || movedRightOverLimit ) {
                var doAction = liElement.data('step2-moving');
                liElement.data('step1', false);
                Dashboard._setStepMoving(liElement, false, false);
                liElement.data('direction', 0);
                liElement.css('left', '0px');
                liElement[0].style[SwipeHelper.transformJSPropertyName] = '';
                event.detail.noAnimation = true;
                event.preventDefault();
                leftSide.fadeOut();
                rightSide.fadeOut();
                if ( doAction ) {
                    var oneVisible = liElement.find('.swipe-ex-icon-container[data-show="true"]:visible');
                    Dashboard._showAllButtons(liElement);
                    oneVisible.data('no-cancel', "1");
                    oneVisible[0].click();
                }
            } else if ( liElement.data('step1') === true ) {
                liElement.data('direction', 0);
            } else {
                leftSide.fadeOut();
                rightSide.fadeOut();
                if ( Dashboard.canPerformAction ) {
                    var actionButton = $(Dashboard.activeElement.find('.fa-check')[0]).data('action-button');
                    if ( actionButton !== undefined ) {
                        $('#' + actionButton).click();
                    }
                }
            }
            Dashboard.canPerformAction = false;
            Dashboard.canPerformActionEx = false;
            Dashboard.activeElement = null;
        }        
    },
    handleDelete: function(event) {
        var deletionItem = $(event.detail.insertBefore);
        if ( !deletionItem.hasClass('dashboard-item__delete-container') ) {
            return;
        }
        var found = false;
        var liElement = event.target;
        while (!found) {
            if ( liElement.tagName === 'LI' ) {
                found = true;
                break;
            }
            liElement = liElement.parentNode;
        }
        if ( !found ) {
            return;
        }
        
        var pop = $('#confirmDeletePopup');
        var submitButton = pop.find('[data-handlers*="confirmDeleteButton"]');
        pop.data('popup-id', 'confirmDeletePopup');
        submitButton.off('click');
        submitButton.on('click', function(e) {
            var action = $(liElement).data('deleteAction');
            Popup.close(pop);
            ajaxPostWithToken(action, 
                function(data) {
                    if ( data.success ) {
                        // Pa�alinam dashboard įra�ą i� sąra�o.
                        $('#dashboard-item-' + data.id)[0].remove();
                    } else {
                        //console.log('Item deletion fail.');
                    }
                },
                function(data) {
                    //console.log('exception while deleting item.');
                },
                $('[name="_token"]').val()
            );
        });
        Popup.show(pop);
    },
    handleBeforeSwipe: function(event) {
        var found = false;
        var liElement = event.target;
        while (!found) {
            if ( liElement.tagName === 'LI' ) {
                found = true;
                break;
            }
            liElement = liElement.parentNode;
        }
        if ( found ) {
            liElement = $(liElement);
            if ( liElement.hasClass('no-swipe') ) {
                event.preventDefault();
                return;
            }
            
            var leftSide = liElement.find('.dashboard-item__leftAction');
            var rightSide = liElement.find('.dashboard-item__rightAction');
            
            leftSide.fadeIn();
            rightSide.fadeIn();
            Dashboard.activeElement = liElement;
        }
    },
    handleReorder: function(event) {
        if ( event.detail.direction !== event.detail.directionBefore ) {
            var found = false;
            var liElement = event.target;
            while (!found) {
                if ( liElement.tagName === 'LI' ) {
                    found = true;
                    break;
                }
                liElement = liElement.parentNode;
            }
            Dashboard.activeElement = $(liElement);
            var oEditName = null;
            var oEditColors = null;
            if ( found ) {
                var itemId = $(liElement).data('item');
                oEditName = $('#editName_' + itemId);
                oEditColors = $('#editColors_' + itemId);
            }
            if ( event.detail.direction === 'unknown' ) {
                if ( !oEditName.is(':visible') ) {
                    oEditName.fadeIn();
                }
                if ( !oEditColors.is(':visible') ) {
                    oEditColors.fadeIn();
                }
                Dashboard._showDeletionArea();
            } else if ( event.detail.direction === 'horizontal' ) {
                Dashboard._hideDeletionArea();
                if ( event.detail.x < 0 ) {
                    //if ( oEditName.is(':visible') ) oEditName.hide();
                    if ( !oEditColors.is(':visible') ) oEditColors.fadeIn();
                } else {
                    //if ( oEditColors.is(':visible') ) oEditColors.hide();
                    if ( !oEditName.is(':visible') ) oEditName.fadeIn();
                }
            } else {
                if ( oEditColors.is(':visible') ) {
                    oEditColors.fadeOut();
                    oEditColors.find('.fa-circle-thin').hide();
                }
                if ( oEditName.is(':visible') ) {
                    oEditName.fadeOut();
                    oEditName.find('.fa-circle-thin').hide();
                }
                if ( event.detail.direction !== 'vertical' ) {
                    Dashboard._hideDeletionArea();
                }
            }
        }
        
        if ( event.detail.direction === 'horizontal' ) {
            var w = Dashboard.activeElement.find('.swipe-edit-name').width();
            var canDoEditAt = w * 0.5;
            w = w * 0.8;
            if ( event.detail.x < 0 ) { // Slenkam i kaire, vadinasi noresim keisti spalvas
                var circle = Dashboard.activeElement.find('.swipe-edit-colors .fa-circle-thin');
                canDoEditAt *= -1;
                w *= -1;
                if ( event.detail.x < canDoEditAt ) {
                    if ( !circle.is(':visible') ) circle.fadeIn();
                    Dashboard.canEditColor = true;
                } else {
                    if ( circle.is(':visible') ) circle.fadeOut();
                    Dashboard.canEditColor = false;
                }
                if ( event.detail.x < w ) {
                    event.detail.x = w;
                }
            } else { // Slenkam i desine, vadinasi noresim keisti pavadinima
                var circle = Dashboard.activeElement.find('.swipe-edit-name .fa-circle-thin');
                if ( event.detail.x > canDoEditAt ) {
                    if ( !circle.is(':visible') ) circle.fadeIn();
                    Dashboard.canEditName = true;
                } else {
                    if ( circle.is(':visible') ) circle.fadeOut();
                    Dashboard.canEditName = false;
                }
                if ( event.detail.x > w ) {
                    event.detail.x = w;
                }
            }
        } else if ( event.detail.direction === '' ) {            
            if ( Dashboard.canEditName ) {
                Dashboard.canEditName = false;
                Dashboard.showEditName(Dashboard.activeElement);
            } else if ( Dashboard.canEditColor ) {
                Dashboard.canEditColor = false;
                Dashboard.showEditColors(Dashboard.activeElement);
            }
            Dashboard._hideDeletionArea();
            Dashboard.activeElement = null;
        }
        
        return true;
    },
    showEditName: function($item) {
        var pop = $('#dashboardEditNamePopup');
        pop.data('item', $item.data('item'));
        var error = pop.find('.errors');
        var nameField = $item.find('.dashboard-item__name .h1');
        var oldName = nameField.text();
        error.hide();
        pop.find('#old_name').val(oldName);
        pop.find('#new_name').val('');
        pop.find('#submitEditName').off('click');
        pop.find('#submitEditName').on('click', function(e) {
            error.hide();
            var action = pop.data('save-name-action');
            var token = $('[name="_token"]');
            var data = {
                _token : token.val(),
                item : $item.data('item'),
                name : pop.find('#new_name').val()
            };
            ajaxPostWithData(action, function(data) { // OK
                if ( data.success ) {
                    nameField.text(pop.find('#new_name').val());
                } else {
                    if ( data.error !== '' ) {
                        error.text(data.error);
                    } else {
                        error.text(error.data('original'));
                    }
                    error.show();
                }
                Popup.close(pop);
            }, function(data) { // Fail
                error.text(error.data('original'));
                error.show();
            }, data);
        });
        Popup.show(pop);
    },
    showEditColors : function($item) {
        var pop = $('#dashboardEditColorsPopup');
        pop.data('item', $item.data('item'));
        var error = pop.find('.errors');
        var textColor = pop.find('#color_text');
        var backColor = pop.find('#color_back');
        var previewHeader = pop.find('.dashboard-item__preview .dashboard-item__header');
        error.hide();
        textColor.css('background-color', $item.find('.dashboard-item__header').css('color'));
        textColor.css('color', $item.find('.dashboard-item__header').css('background-color'));
        backColor.css('background-color', $item.find('.dashboard-item__header').css('background-color'));
        backColor.css('color', $item.find('.dashboard-item__header').css('color'));
        previewHeader.css('color', textColor.css('background-color'));
        previewHeader.css('background-color', textColor.css('color'));
        
        var pick_text = pop.find('#color_text_container .picker_text_container').colorpicker({
            color: textColor.css('background-color'),
            inline: true,
            container: true,
            useAlpha: false
        });
        pick_text.on('colorpickerChange', function(e) {
            textColor.css('background-color', e.color);            
            backColor.css('color', e.color);
            previewHeader.css('color', e.color);
        });
        var pick_back = pop.find('#color_back_container .picker_text_container').colorpicker({
            color: backColor.css('background-color'),
            inline: true,
            container: true,
            useAlpha: false
        });
        pick_back.on('colorpickerChange', function(e) {
            textColor.css('color', e.color);            
            backColor.css('background-color', e.color);
            previewHeader.css('background-color', e.color);
        });
        
        pop.find('#submitEditColors').off('click');
        pop.find('#submitEditColors').on('click', function(e) {
            error.hide();
            var action = pop.data('save-colors-action');
            var token = $('[name="_token"]');
            var data = {
                _token : token.val(),
                item : $item.data('item'),
                color_text : textColor.css('background-color'),
                color_back : backColor.css('background-color')
            };
            ajaxPostWithData(action, function(data) { // OK
                if ( data.success ) {
                    $item.find('.dashboard-item__header').css('color', textColor.css('background-color'));
                    $item.find('.dashboard-item__header').css('background-color', backColor.css('background-color'));
                } else {
                    if ( data.error !== '' ) {
                        error.text(data.error);
                    } else {
                        error.text(error.data('original'));
                    }
                    error.show();
                }
                Popup.close(pop);
            }, function(data) { // Fail
                error.text(error.data('original'));
                error.show();
            }, data);
        });
        Popup.show(pop);
    },
    cleanUp: function() {
        Dashboard._hideDeletionArea();
        // Grazinam overflow reiksme desktop'e
        if ( !Mobile.isMobile() ) {
            $('.dashboard-list').css('overflow', 'hidden');
        }
    },
    handleAreaStatusChange: function(areaId, areaStatus) {
        var miniDisarm = $('.area_id_' + areaId + '_1');
        var miniArm = $('.area_id_' + areaId + '_2');
        var miniStay = $('.area_id_' + areaId + '_3');
        var miniSleep = $('.area_id_' + areaId + '_4');
        miniDisarm.hide();
        //miniDisarm.data('show', false);
        miniDisarm.attr('data-show', false);
        miniArm.hide();
        //miniArm.data('show', false);
        miniArm.attr('data-show', false);
        miniStay.hide();
        //miniStay.data('show', false);
        miniStay.attr('data-show', false);
        miniSleep.hide();
        //miniSleep.data('show', false);
        miniSleep.attr('data-show', false);
        if ( areaStatus === 0 ) {
            miniDisarm.show();
            //miniDisarm.data('show', true);
            miniDisarm.attr('data-show', true);
            miniArm.show();
            //miniArm.data('show', true);
            miniArm.attr('data-show', true);
            miniStay.show();
            //miniStay.data('show', true);
            miniStay.attr('data-show', true);
            miniSleep.show();
            //miniSleep.data('show', true);
            miniSleep.attr('data-show', true);
        } else if ( areaStatus === 1 ) {
            miniArm.show();
            //miniArm.data('show', true);
            miniArm.attr('data-show', true);
            miniStay.show();
            //miniStay.data('show', true);
            miniStay.attr('data-show', true);
            miniSleep.show();
            //miniSleep.data('show', true);
            miniSleep.attr('data-show', true);
        } else {
            miniDisarm.show();
            //miniDisarm.data('show', true);
            miniDisarm.attr('data-show', true);
        }
    }
};

var CameraHandler = {
    errorRegistered : false,
    create: function() {
        $(window).resize(CameraHandler.onWindowResize);
        
        var aElements = $('.fullscreen_capable');
        //console.log('Fullscreen capable elements [' + aElements.length + ']');
        $.each(aElements, function(iIndex, oElement){
            if ( typeof platformMobile !== 'undefined' ) {
                CameraHandler.onStop(oElement);
            } else {
                CameraHandler.onPlay(oElement);
            }
        });
    },
    showFullScreen: function(AItemId) {
        //console.log('Full screen initialize for [' + AItemId + ']');
        var oFullScreenElement = $('#cam_fullscreen');
        var oStream = $(AItemId);
        var sSrc = $(oStream).attr('src');
        oStream.off('error');
        $(oStream).attr('src', '');
        
        oStream.detach().appendTo($('#cam_fullscreen'));
        oStream.removeClass('content_aspect');
        if ( typeof platformMobile !== 'undefined' ) {
            oStream.addClass('cam_fullscreen_mobile');
        }
        
        oFullScreenElement.removeClass('cam_fullscreen_inactive');
        oFullScreenElement.addClass('cam_fullscreen_active');
        
        CameraHandler.setVideoSize(oStream);
        
        $(oFullScreenElement).on('click.camera', function(e){
            e.preventDefault();
            CameraHandler.restore(AItemId);
        });
        if ( $(oStream).data('type') === 'mp4' ) {
            $(oStream).on('error', CameraHandler.handleError);
            $(oStream).attr('src', sSrc);
            oStream[0].play();
        }
        
        var backButton = $('#theBackButton');
        if ( backButton.length > 0 ) {
            backButton.attr('href', '#func:CameraHandler.restore;s' + AItemId);
        }
        preventSideMenuSwipe = true;
    },
    restore: function(AItemId) {
        preventSideMenuSwipe = false;
        var backButton = $('#theBackButton');
        if ( backButton.length > 0 ) {
            backButton.attr('href', backButton.data('original-url'));
        }
        //console.log('Restoring [' + AItemId + '] from fullscreen mode.');
        var oFullScreenElement = $('#cam_fullscreen');
        var oStream = $(AItemId);
        var sSrc = $(oStream).attr('src');
        oStream.off('error');
        $(oStream).attr('src', '');
        
        $(oFullScreenElement).off('click.camera');
        oFullScreenElement.removeClass('cam_fullscreen_active');
        oFullScreenElement.addClass('cam_fullscreen_inactive');
        
        if ( typeof platformMobile !== 'undefined' ) {
            oStream.removeClass('cam_fullscreen_mobile');
        }
        var sType = '_' + $(oStream).data('type');
        var sContainerId = AItemId.replace(sType, '_container');
        oStream.detach().appendTo($(sContainerId));
        oStream.addClass('content_aspect');
        CameraHandler.setVideoSize(oStream);
        if ( sType === '_mp4' ) {
            oStream.on('error', CameraHandler.handleError);
            $(oStream).attr('src', sSrc);
            oStream[0].play();
        }
    },
    onWindowResize: function() {
        //console.log('Window resized. Resizing camera views.');
        var aElements = $('.fullscreen_capable');
        var oFullScreenElement = $('#cam_fullscreen');
        aElements.push(oFullScreenElement);
        $.each(aElements, function(iIndex, oElement) {
            //console.log('Found fullscreen capable element [' + iIndex + ']');
            var oStream = $(oElement).find('[data-type="mjpeg"]');
            if ( oStream.length !== 0 ) {
                oStream.off('error');
                CameraHandler.setVideoSize(oStream);
            }
        });
    },
    setVideoSize: function(oStream) {
        if ( $(oStream).data('type') !== 'mjpeg' || $(oStream).data('state') === 'stop' ) {
            return;
        }
        //console.log('Resizing [' + oStream.attr('id') + ']');
        var sSource = $(oStream).data('src');
        var sWidth = '&max_width=' + oStream.width();
        var sHeight = '';
        if ( $(oStream).hasClass('cam_fullscreen_mobile') ) {
            var nRatio = 16/9;
            var nCurrentRatio = oStream.width() / oStream.height();
            if ( nCurrentRatio > nRatio ) {
                sHeight = '';
                sWidth = '&max_height=' + oStream.height();
            }
        }
        //console.log('New max width: ' + sWidth + '; Height: ' + sHeight);
        oStream.on('error', CameraHandler.handleError);
        oStream.attr('src', sSource + sWidth + sHeight);
    },
    onStop: function(oContainer) {
        var oPlayStop = $(oContainer).parent();
        var oStreamImg = $(oContainer).find('[data-type="mjpeg"]');
        var oStreamMp = $(oContainer).find('video');
        var oSnapshot = $(oContainer).find('img[id*="_snapshot"]');
        var oPause = $(oContainer).parent().find('.camera__pause');
        //console.log('Stopping video for [' + $(oContainer).attr('id') + ']');
        $(oContainer).off('click.fullscreen');
        
        oPlayStop.removeClass('camera__controls-playing');
        oPlayStop.removeClass('camera__controls-playing-hover');
        oPlayStop.addClass('camera__controls-paused');
        $(oPlayStop).on('click', function() {
            CameraHandler.onPlay(oContainer);
        });
        
        var sSnapshot = $(oSnapshot).data('src');
        var sWidth = '&max_width=' + oStreamImg.width();
        sSnapshot += sWidth;
        oSnapshot.off('error');
        oSnapshot.on('error', CameraHandler.handleError);
        oSnapshot.attr('src', sSnapshot);
        
        oStreamImg.hide();
        oStreamImg.off('error');
        oStreamImg.attr('src', '');
        oStreamImg.data('state', 'stop');
        
        if ( !oStreamMp[0].paused ) {
            oStreamMp[0].pause();
        }
        oStreamMp.hide();
        
        oSnapshot.show();
        
        $(oPause).off('click');
        oPause.hide();
    },
    onPlay: function(oContainer) {
        var oStreamImg = $(oContainer).find('[data-type="mjpeg"]');
        var oStreamMp = $(oContainer).find('video');
        var sSourceType = $(oContainer).data('type');
        var oPlayStop = $(oContainer).parent();
        var oSnapshot = $(oContainer).find('img[id*="_snapshot"]');
        var oPause = $(oContainer).parent().find('.camera__pause');
        var oStream = null;
        var bPlay = false;
        
        //console.log('Starting video for [' + $(oContainer).attr('id') + ']. Type: [' + sSourceType + ']');
        if ( sSourceType === 'mp4' ) {
            oStream = oStreamMp;
            oStreamMp.off('error');
            oStreamMp.on('error', CameraHandler.handleError);
            oStreamMp.attr('src', $(oStreamMp).data('src'));
            bPlay = true;
        } else if ( sSourceType === 'mjpeg' ) {
            oStream = oStreamImg;
            $(oStreamImg).data('state', 'play');
            oStreamImg.off('error');
            CameraHandler.setVideoSize(oStreamImg);               
        }

        $(oContainer).on('click.fullscreen', function(e) {
            e.preventDefault();
            CameraHandler.showFullScreen( '#' + oStream.attr('id') );
        });

        oPlayStop.removeClass('camera__controls-paused');
        if ( typeof platformMobile !== 'undefined' ) {
            oPlayStop.addClass('camera__controls-playing');
        } else {
            oPlayStop.addClass('camera__controls-playing-hover');            
        }
        $(oPlayStop).off('click');
        
        oSnapshot.hide();
        
        oStream.show();
        if ( bPlay ) {
            oStream[0].play();
        }
        
        oPause.show();
        $(oPause).on('click', function(e) {
            //console.log('Pause clicked for [' + $(oContainer).attr('id') + ']');
            CameraHandler.onStop(oContainer);
            e.stopPropagation();
        });
    },
    handleError: function() {
        if ( CameraHandler.errorRegistered ) {
            return;
        }
        console.log('A video snapshot/stream resulted in an error. Trying to reload cameras.');
        CameraHandler.errorRegistered = true;
        
        $('#reload_cameras')[0].click();        
    },
    confirmCameraDetails: function($confirmButton, streamData) {
        if ( $confirmButton === undefined ) return;
        $confirmButton.attr('disabled', 'disabled');
        
        var popupId = $confirmButton.data('popup-id');
        var pop = $('#' + popupId);
        var errorBox = pop.find('#cameraDetailError');
        var statusBox = pop.find('.inlineLoader');
        var statusText = pop.find('#inlineLoader-statusText');
        var url = $confirmButton.data('post');
        var cameraId = $confirmButton.data('param1');
        var systemBox = pop.find('#systemBox');
        var selectedSystem = 0;
        var checkStream = $confirmButton.data('param2');
        var streamCheckSession = 0;
        if ( cameraId === undefined ) cameraId = 0;
        
        errorBox.hide();
        statusBox.hide();
        
        if ( systemBox.length > 0 ) {
            var selectedValue = systemBox.data('selected-value');
            if ( selectedValue !== undefined && selectedValue !== '*' ) {
                selectedSystem = selectedValue;
            }
        }
        
        var requestData = {
            _token : $('[name="_token"]').val(),
            cameraId : cameraId,
            cameraName : pop.find('#cameraDetail_name').val(),
            assignCamera : selectedSystem,
            cameraUrl : pop.find('#cameraDetail_url').val(),
            cameraPort : pop.find('#cameraDetail_port').val(),
            cameraUser : pop.find('#cameraDetail_user').val(),
            cameraPass : pop.find('#cameraDetail_pass').val()
        };
        if ( streamData !== undefined ) {
            requestData.rtsp = streamData.rtsp;
            requestData.mjpeg = streamData.mjpeg;
        }
        
        var atLeastOneIsModified = ModifiedCheckHandler.isModified('cameraDetail_url')
            || ModifiedCheckHandler.isModified('cameraDetail_port')
            || ModifiedCheckHandler.isModified('cameraDetail_user')
            || ModifiedCheckHandler.isModified('cameraDetail_pass');
    
        if ( (ModifiedCheckHandler.isModified('cameraDetail_name') || ModifiedCheckHandler.isModified('systemBox')) && !atLeastOneIsModified ) {
            ajaxPostWithData(url, 
                function(data) {
                    if ( data.success ) {
                        //console.log('reload in production');
                        window.location.reload(true);
                    } else {
                        errorBox.text(data.error);
                        errorBox.show();
                        $confirmButton.removeAttr('disabled');
                        suppressPreloader = 0;
                    }
                },
                function(data) {
                    console.log(data);
                    $confirmButton.removeAttr('disabled');
                    suppressPreloader = 0;
                },
                requestData
            );
            return;
        } else if ( atLeastOneIsModified ) {            
            suppressPreloader = 1;
            statusText.text(statusText.data('default'));
            statusBox.show();
            
            if ( streamData === undefined ) {
                url = checkStream;
            }
            ajaxPostWithData(url, 
                function(data) {
                    if ( data.success ) {
                        statusText.text(data.nextStepText);
                        
                        if ( streamData === undefined ) {
                            streamCheckSession = data.session;
                            setTimeout(function() {
                                CameraHandler.waitForStreamDetection($confirmButton, streamCheckSession);
                            }, 1000);
                        } else {
                            window.location.reload(true);
                        }
                    } else {
                        statusBox.hide();
                        errorBox.text(data.error);
                        errorBox.show();
                        suppressPreloader = 0;
                        $confirmButton.removeAttr('disabled');
                    }
                },
                function(data) {
                    statusBox.hide();
                    console.log(data);
                    suppressPreloader = 0;
                    $confirmButton.removeAttr('disabled');
                },
                requestData
            );
            return;
        } else {
            Popup.close($confirmButton);
        }
    },
    prepareCameraEdit: function($this, popupId) {
        var cameraUrl = $this.data('url');
        var cameraPort = $this.data('port');
        var userName = $this.data('user');
        var password = $this.data('pass');
        var camName = $this.data('name');
        var pop = $('#' + popupId);
        var isNew = false;
        var statusBox = pop.find('.inlineLoader');
        var errorBox = pop.find('#cameraDetailError');
        var assigned = $this.data('assigned');
        var streamCheckAction = $('#checkStream');
        if ( streamCheckAction.length > 0 ) {
            $this.data('param2', streamCheckAction.val());
        }
        statusBox.hide();
        errorBox.hide();        
        
        if ( cameraUrl === '' && cameraPort === '' && userName === '' && password === '' ) {
            isNew = true;
        }
        
        if ( isNew ) {
            pop.find('#addCameraTitle').show();
            pop.find('#editCameraTitle').hide();
            pop.find('#cameraDetail_url').removeAttr('disabled');
            pop.find('#cameraDetail_port').removeAttr('disabled');
            pop.find('#cameraDetail_user').removeAttr('disabled');
            pop.find('#cameraDetail_pass').removeAttr('disabled');
        } else {
            pop.find('#addCameraTitle').hide();
            pop.find('#editCameraTitle').show();
            pop.find('#cameraDetail_url').attr('disabled', 'disabled');
            pop.find('#cameraDetail_port').attr('disabled', 'disabled');
            pop.find('#cameraDetail_user').attr('disabled', 'disabled');
            pop.find('#cameraDetail_pass').attr('disabled', 'disabled');
        }
        
        pop.find('#cameraDetail_name').val(camName);
        pop.find('#cameraDetail_url').val(cameraUrl);
        pop.find('#cameraDetail_port').val(cameraPort);
        pop.find('#cameraDetail_user').val(userName);
        pop.find('#cameraDetail_pass').val(password);
        pop.find('#cameraDetailError').hide();
        var systemBox = pop.find('#systemBox');
        if ( assigned === undefined || assigned === '' || assigned === 0 || assigned === '0' ) {
            systemBox.data('selected-value', '*');
            systemBox.data('selected-index', '0');
        } else {
            systemBox.data('selected-value', assigned);
            systemBox.data('selected-index', '*');
        }
        ListboxHandler.select(systemBox);
        ModifiedCheckHandler.reset();
    },
    waitForStreamDetection: function($confirmButton, sessionId) {
        var popupId = $confirmButton.data('popup-id');
        var pop = $('#' + popupId);
        var errorBox = pop.find('#cameraDetailError');
        var statusBox = pop.find('.inlineLoader');
        var statusText = pop.find('#inlineLoader-statusText');
        var checkStream = $confirmButton.data('param2');
        
        if ( !pop.is(':visible') ) {
            return;
        }
        
        suppressPreloader = 1;
        ajaxGet(checkStream + '?session=' + sessionId + '&_token=' + $('[name="_token"]').val(),
            function(data) {
                if ( data.success ) {
                    if ( data.status === 'ok' ) {
                        statusText.text(data.nextStepText);
                        CameraHandler.confirmCameraDetails($confirmButton, data);
                    } else {
                        statusText.text(data.nextStepText);
                        setTimeout(function() {
                            CameraHandler.waitForStreamDetection($confirmButton, sessionId);
                        }, 5000);
                    }
                } else {
                    statusBox.hide();
                    errorBox.text(data.error);
                    errorBox.show();
                    suppressPreloader = 0;
                    $confirmButton.removeAttr('disabled');
                }
            },
            function(data) {
                statusBox.hide();
                console.log(data);
                suppressPreloader = 0;
                $confirmButton.removeAttr('disabled');
            }
        );
    }
};

var CamStreamSwitcher = {
    create: function() {
        var aElements = $('.camera__stream-type');
        $.each(aElements, function(iIndex, oElement) {
            var aButtons = $(oElement).find('a');
            $.each(aButtons, function(iButton, oButton) {
                $(oButton).on('click', function(e) {
                    e.preventDefault();
                    CamStreamSwitcher.onSwitch($(oButton).attr('id'));
                });
            });
        });
    },
    onSwitch: function(sSwitchType) {
        var sUrl = $('#' + sSwitchType).attr('data-submit');
        $.ajax({
            url: sUrl,
            method: 'GET',
            success: function (data) {
                var oResponse = JSON.parse(data);
                if ( oResponse.hasOwnProperty('error') ) {
                    console.log('Error received while trying to switch stream type: ' + oResponse.error);
                    return; // Some error.
                }
                
                if ( !oResponse.hasOwnProperty('camera') || !oResponse.hasOwnProperty('type')) {
                    console.log('Unexpected data received.');
                    return; // Unexpected data received.
                }
                
                var oButtonContainer = $('.camera__stream-type_' + oResponse.camera);
                if ( oButtonContainer.length === 0 ) {
                    console.log('Button container for camera [' + oResponse.camera + '] not found.');
                    return; //Not found?
                }
                
                var aButtons = $(oButtonContainer).find('a');
                $.each(aButtons, function(iButton, oButton) {
                    if ( $(oButton).attr('id') === oResponse.type + '_' + oResponse.camera ) {
                        $(oButton).removeClass('btn-primary-off');
                        $(oButton).addClass('btn-primary');
                    } else {
                        $(oButton).removeClass('btn-primary');
                        $(oButton).addClass('btn-primary-off');
                    }
                });
            },
            error: function (jqXHR) {
                if (jqXHR.status === 403) {
                    GeneralUI._displayValidationErrors({user: 'Unauthorized request'});
                }
            }
        });
    }
};

var ModifiedCheckHandler = {
    components: [],
    init: function() {
        var observable = $('[data-handlers*="modifiedCheck"]');
        $.each(observable, function(iIndex, iObservable) {
            ModifiedCheckHandler.components[$(iObservable).attr('id')] = false;
            if ( $(iObservable).hasClass('listbox') ) {
                $(iObservable).on('click', ModifiedCheckHandler.modified);
            } else {
                $(iObservable).on('change', ModifiedCheckHandler.modified);
            }
        });
    },
    reset: function(component) {
        if ( component !== undefined ) {
            ModifiedCheckHandler.components[component] = false; 
        } else {
            var components = Object.getOwnPropertyNames(ModifiedCheckHandler.components);
            $.each(components, function(iIndex, iComponent) {
                ModifiedCheckHandler.components[iComponent] = false;
            });
        }
    },
    modified: function() {
        var id = $(this).attr('id');
        ModifiedCheckHandler.components[id] = true;
    },
    isModified: function(component) {
        if ( ModifiedCheckHandler.components[component] === undefined ) return false;
        return ModifiedCheckHandler.components[component];
    }
};


// URL - ir taip ai�ku
// good - Callback funkcija, kai užklausa pavyksta.
// bad - Callback funkcija, kai užklausa nepavyksta arba gražina error.
function ajaxPostWithToken(url, good, bad, token) {
    $.ajax({
        url: url,
        dataType: 'json',
        data: {
            _token: token
        },
        method: 'POST',
        success: good,
        error: bad
    });
}
function ajaxPostWithData(url, good, bad, requestData) {
    $.ajax({
        url: url,
        dataType: 'json',
        data: requestData,
        method: 'POST',
        success: good,
        error: bad
    });
}
function ajaxGet(url, good, bad) {
    $.ajax({
        url: url,
        method: 'GET',
        success: good,
        error: bad
    });
}

var User = {
    init: function() {
        var accountDeletionButton = $('.confirmAccountDeletion');
        if ( accountDeletionButton.length > 0 && accountDeletionButton[0].tagName === 'BUTTON' ) {
            accountDeletionButton.on('click', function(e) { User.deleteAccount(e); });
        }
        
        if ( typeof handleDeviceUsers === 'undefined' ) return;
        
        var pgms = $('.user_pgm, .userCanEdit, .user_area');
        pgms.on('change', function(e) {
            var userId = $(e.target).data('user');
            $('#user_' + userId).find('.user_mod').addClass('user_mod-yes');
        });
        var ens = $('.userEnabled');
        ens.on('change', function(e) {
            var userId = $(e.target).data('user');
            var oEval = $('#user_' + userId).find('.userEval');
            var eval = parseInt(oEval.val());
            if ( $(e.target).is(':checked') ) {
                eval |= 1;
            } else {
                eval &= 0xfffffffe;
            }
            oEval.val(eval);
            $('#user_' + userId).find('.user_mod').addClass('user_mod-yes');
        });
        var su = $('#saveUsers');
        su.on('click', function(e) {
            $('.deviceUserList').data('start-from', 0);
            User.saveChanges(e);
        });
        
        var emailField = $('#popdu_email');
        var canEditUsersCheck = $('#popdu_editList');
        emailField.on('input', function(e) {
            var currentText = $.trim($(e.target).val());
            canEditUsersCheck.attr('disabled', !isEmail(currentText) || $('#popdu_editList').hasClass('master'));
        });
        var codeFields = $('.userCode');
        codeFields.on('input', function(e) {
            var userId = $(e.target).data('user');
            $('#user_' + userId).find('.user_mod').addClass('user_mod-yes');
        });
    },
    deleteUser: function($this) {
        var userItem = $('#user_' + $this.data('param1'));
        userItem.hide();
        userItem.find('.user_mod').addClass('user_mod-yes').addClass('user_mod-deleted');
    },
    showAddUser: function($this, popupId) {
        var pop = $('#' + popupId);
        pop.find('.addNewUser').show();
        pop.find('.editUser').hide();
        
        pop.find('#popdu_enabled').prop('checked', true);
        pop.find('#popdu_email').val('');
        pop.find('#popdu_phone').val('');
        pop.find('#popdu_editList').prop('checked', false);
        pop.find('#popdu_editList').attr('disabled', false);
        pop.find('#all').prop('checked', false);
        pop.find('#popdu_pgm1').prop('checked', false);
        pop.find('#popdu_pgm2').prop('checked', false);
        pop.find('#popdu_pgm3').prop('checked', false);
    },
    showEditUser: function($this, popupId) {
        var pop = $('#' + popupId);
        pop.find('.addNewUser').hide();
        pop.find('.editUser').show();
        
        var userId = $this.data('param1');
        var userItem = $('#user_' + userId);
        
        pop.find('#popdu_enabled').prop('checked', userItem.find('.userEnabled').is(':checked'));
        pop.find('#popdu_email').val($.trim(userItem.find('.userName').text()));
        pop.find('#popdu_phone').val($.trim(userItem.find('.user_phone').text()));
        pop.find('#popdu_code').val($.trim(userItem.find('.userCode').val()));
        pop.find('#popdu_editList').prop('checked', userItem.find('.userCanEdit').is(':checked'));
        if ( userItem.find('.isMaster').val() === '1' ) {
            pop.find('#popdu_editList').addClass('master');
        } else {
            pop.find('#popdu_editList').removeClass('master');
        }
        pop.find('#popdu_editList').attr('disabled', userItem.find('.isMaster').val() === '1' || !isEmail(pop.find('#popdu_email').val()) );  
        var allChecked = false;
        var pgm1 = userItem.find('.user_pgm-1');
        var pgm2 = userItem.find('.user_pgm-2');
        var pgm3 = userItem.find('.user_pgm-3');
        if ( pgm1.length > 0 || pgm2.length > 0 || pgm3.length > 0 ) {
            pop.find('#popdu_pgm1').prop('checked', pgm1.is(':checked'));
            pop.find('#popdu_pgm2').prop('checked', pgm2.is(':checked'));
            pop.find('#popdu_pgm3').prop('checked', pgm3.is(':checked'));
            allChecked = pgm1.is(':checked') && pgm2.is(':checked') && pgm3.is(':checked');
        }
        var areas = userItem.find('.user_area');
        if ( areas.length > 0 ) {
            var checkCount = 0;
            $.each(areas, function(i,o) {
                o = $(o);
                if ( o.is(':checked') ) {
                    checkCount++;
                }
                pop.find('#popdu_area_' + o.val()).prop('checked', o.is(':checked'));
            });
            allChecked = areas.length === checkCount;
        }        
        pop.find('#all').prop('checked', allChecked);
    },
    addUser: function($this) {
        var pop = $('#' + $this.data('popup-id'));
        var users = $('.deviceUserItem');
        var minId = 11;
        var oUseThis = null;
        var oElementBefore = null;
        var justShow = false;
        var masters = $.trim($('#masters').val());
        if ( masters !== '' ) {
            masters = JSON.parse(masters);
        } else {
            masters = null;
        }
        $.each(users, function(i, o) {
            o = $(o);
            if ( o.data('user') === '' ) {
                return true; // continue;
            }
            
            if ( masters !== null ) {
                while ( masters.indexOf(minId.toString()) > -1 ) {
                    minId++;
                }
            }
            
            var currentId = parseInt(o.data('user'));
            if ( minId < currentId ) {
                return false; // break;
            }
            if ( minId === currentId && !o.is(':visible') ) {
                oUseThis = o;
                justShow = true;
                return false; // break;
            }
            oElementBefore = o;
            if ( minId === currentId ) {
                minId++;
            }
        });
        
        if ( masters !== null ) {
            while ( masters.indexOf(minId.toString()) > -1 ) {
                minId++;
            }
        }
        
        if ( !justShow ) {
            oUseThis = $('#user_').clone(true);
        }
        
        oUseThis.data('user', minId);
        oUseThis.attr('id', 'user_' + minId);
        oUseThis.find('.user_mod').addClass('user_mod-yes').removeClass('user_mod-deleted');
        oUseThis.find('.userName').text(pop.find('#popdu_email').val());
        oUseThis.find('.user_phone').text(pop.find('#popdu_phone').val());
        if ( pop.find('#popdu_pgm1').length > 0 ) {
            var pgm1 = oUseThis.find('.user_pgm-1');
            if ( !justShow ) {
                pgm1.attr('id', 'pgm1_' + minId);
                pgm1.data('user', minId);
                oUseThis.find('[for="pgm1_"]').attr('for', 'pgm1_' + minId);
            }
            pgm1.prop('checked', pop.find('#popdu_pgm1').is(':checked'));
        }
        if ( pop.find('#popdu_pgm2').length > 0 ) {
            var pgm2 = oUseThis.find('.user_pgm-2');
            if ( !justShow ) {
                pgm2.attr('id', 'pgm2_' + minId);
                pgm2.data('user', minId);
                oUseThis.find('[for="pgm2_"]').attr('for', 'pgm2_' + minId);                
            }
            pgm2.prop('checked', pop.find('#popdu_pgm2').is(':checked'));
        }
        if ( pop.find('#popdu_pgm3').length > 0 ) {
            var pgm3 = oUseThis.find('.user_pgm-3');
            if ( !justShow ) {
                pgm3.attr('id', 'pgm3_' + minId);
                pgm3.data('user', minId);
                oUseThis.find('[for="pgm3_"]').attr('for', 'pgm3_' + minId);                
            }
            pgm3.prop('checked', pop.find('#popdu_pgm3').is(':checked'));
        }
        oUseThis.find('.userEval').val(pop.find('#popdu_enabled').is(':checked') ? 1 : 0);
        var en = oUseThis.find('.userEnabled');
        en.attr('id', 'userEnabled_' + minId);
        en.data('user', minId);
        en.prop('checked', pop.find('#popdu_enabled').is(':checked'));
        oUseThis.find('.userEnabled-label').prop('for', 'userEnabled_' + minId);
        oUseThis.find('.editDeviceUser').data('param1', minId);
        oUseThis.find('.deleteDeviceUser').data('param1', minId);
        var canEdit = oUseThis.find('.userCanEdit');
        canEdit.attr('id', 'userCanEdit_' + minId);
        canEdit.data('user', minId);
        canEdit.attr('disabled', !isEmail(pop.find('#popdu_email').val()) );
        canEdit.prop('checked', pop.find('#popdu_editList').is(':checked') && !canEdit.is(':disabled'));
        oUseThis.find('.userCanEdit-label').prop('for', 'userCanEdit_' + minId);
        
        if ( !justShow ) {
            if ( oElementBefore !== null ) {
                var parent = oElementBefore.get(0).parentNode;
                parent.insertBefore(oUseThis.get(0), oElementBefore.get(0).nextSibling);
            } else {
                $('.deviceUserList').get(0).appendChild(oUseThis.get(0));
            }
        }
        oUseThis.removeClass('master');
        oUseThis.show();
    },
    editUser : function($this) {
        var pop = $('#' + $this.data('popup-id'));
        var user = $('#user_' + $this.data('param1'));
        user.find('.user_mod').addClass('user_mod-yes').removeClass('user_mod-deleted');
        user.find('.userName').text(pop.find('#popdu_email').val());
        var phoneText = pop.find('#popdu_phone').val();
        user.find('.user_phone').text(pop.find('#popdu_phone').val());
        if ( phoneText.trim() !== '' ) {
            var userRoleText = user.find('.user_role').text();
            if ( userRoleText.indexOf('(') === -1 ) {
                userRoleText = '(' + userRoleText + ')';
            }
            user.find('.user_role').text(userRoleText);
        } else {
            user.find('.user_role').text(user.find('.user_role').text().replace('(', '').replace(')', ''));
        }
        user.find('.userCode').val(pop.find('#popdu_code').val());
        if ( pop.find('#popdu_pgm1').length > 0 ) {
            user.find('.user_pgm-1').prop('checked', pop.find('#popdu_pgm1').is(':checked'));
        }
        if ( pop.find('#popdu_pgm2').length > 0 ) {
            user.find('.user_pgm-2').prop('checked', pop.find('#popdu_pgm2').is(':checked'));
        }
        if ( pop.find('#popdu_pgm3').length > 0 ) {
            user.find('.user_pgm-3').prop('checked', pop.find('#popdu_pgm3').is(':checked'));
        }
        var oEval = user.find('.userEval');
        var eval = parseInt(oEval.val());
        if ( pop.find('#popdu_enabled').is(':checked') ) {
            eval |= 1;
        } else {
            eval &= 0xfffffffe;
        }
        oEval.val(eval);
        user.find('.userEnabled').prop('checked', pop.find('#popdu_enabled').is(':checked'));
        var canEdit = user.find('.userCanEdit');
        canEdit.attr('disabled', !isEmail(pop.find('#popdu_email').val()) );
        canEdit.prop('checked', pop.find('#popdu_editList').is(':checked') && !canEdit.is(':disabled'));
        var userAreas = user.find('.user_area');
        $.each(userAreas, function(i,o) {
            o = $(o);
            o.prop('checked', pop.find('#popdu_area_' + o.val()).is(':checked'));
        });
    },
    saveChanges: function(e) {
        var modified = $('.user_mod-yes');
        var lastErrors = $('.error-highlight');
        lastErrors.removeClass('error-highlight');
        if ( modified.length === 0 ) {
            console.log('nothing to save.');
            return;
        }
        var maxLen = parseInt($('#max_len').val());
        var currentLen = 0;
        var magicLen = 6;
        var magicLen2 = 7;
        var currentLen = magicLen;
        var data = [];
        var action = $('.deviceUserList').data('save');
        var token = $('[name="_token"]').val();
        var packets = [];
        var startFrom = $('.deviceUserList').data('start-from');
        if ( startFrom === undefined ) {
            startFrom = 0;
        } else {
            startFrom = parseInt(startFrom);
        }
        $.each(modified, function(i, o) {
            var user = $(o.parentNode);
            var id = user.data('user');            
            var name = $.trim(user.find('.userName').text());
            var phone = $.trim(user.find('.user_phone').text());
            var oldName = user.find('.userName').data('old');
            if ( !isNaN(maxLen) ) {
                currentLen += name.length + phone.length + id.toString().length + magicLen2;
                if ( currentLen < maxLen ) {
                    data.push({
                        id : id,
                        name : name,
                        oldName : oldName,
                        phone : phone,
                        eval : parseInt(user.find('.userEval').val()),
                        pgm1 : ( user.find('#pgm1_'+id).length > 0 ? user.find('#pgm1_'+id).is(':checked') : false),
                        pgm2 : ( user.find('#pgm2_'+id).length > 0 ? user.find('#pgm2_'+id).is(':checked') : false),
                        pgm3 : ( user.find('#pgm3_'+id).length > 0 ? user.find('#pgm3_'+id).is(':checked') : false),
                        deleted : $(o).hasClass('user_mod-deleted'),
                        canEdit : ( user.find('#userCanEdit_' + id).length > 0 ? user.find('#userCanEdit_' + id).is(':checked') : false)
                    });
                } else {
                    currentLen = magicLen + name.length + phone.length + id.toString().length + magicLen2;
                    if ( currentLen > maxLen ) {
                        currentLen = magicLen;
                        user.addClass('error-highlight');
                        return true;
                    }

                    packets.push(data);
                    data = []; // Isvalom

                    data.push({
                        id : id,
                        name : name,
                        oldName : oldName,
                        phone : phone,
                        eval : parseInt(user.find('.userEval').val()),
                        pgm1 : ( user.find('#pgm1_'+id).length > 0 ? user.find('#pgm1_'+id).is(':checked') : false),
                        pgm2 : ( user.find('#pgm2_'+id).length > 0 ? user.find('#pgm2_'+id).is(':checked') : false),
                        pgm3 : ( user.find('#pgm3_'+id).length > 0 ? user.find('#pgm3_'+id).is(':checked') : false),
                        deleted : $(o).hasClass('user_mod-deleted')
                    });
                }
            } else {
                var areas = [];
                var areaChecks = user.find('.user_area');
                $.each(areaChecks, function(i,o) {
                    areas.push({
                        no : $(o).data('no'),
                        value : $(o).is(':checked')
                    });
                });
                data.push({
                    id : id,
                    name : name,
                    oldName : oldName,
                    phone : phone,
                    deleted : $(o).hasClass('user_mod-deleted'),
                    areas : areas,
                    code : user.find('.userCode').val()
                });
            }
        });
        if ( data.length > 0 ) {
            packets.push(data);
        }
                
        var setProgress = function (progress, max) {
            var progressBarSvg = $('#progress-bar');
            if(progressBarSvg.length === 0) {
                return;
            }

            var progressBar = progressBarSvg.find('path');
            var progressText = progressBarSvg.find('text');

            var percent = progress / max;
            var value = Math.round(250 * percent);

            progressBar.attr('stroke-dasharray', value + ',250.2');
            progressText.text(Math.round(percent*100) + '%');
        };
        var sender = function(i) {
            suppressPreloader++;
            ajaxPostWithData(action, function(r) {
                setProgress(i+1,packets.length);
                if ( i < packets.length-1 && r.success ) {
                    sender(++i);
                } else if ( !r.success ) {
                    setProgress(i, packets.length);
                    $('.deviceUserList').data('start-from', i);
                    errorText.text(r.error);
                    okButton.show();
                    errorText.show();
                    retryButton.show();
                } else {
                    $('.deviceUserList').data('start-from', 0);
                    okButton.show();
                    messageContent.find('p').text($('.deviceUserList').data('completion-note'));
                    modified.removeClass('user_mod-yes');
                }
                suppressPreloader--;
            }, function(r) {
                $('.deviceUserList').data('start-from', 0);
                suppressPreloader--;
            }, {
                _token : token,
                data : packets[i],
                system : $('.deviceUserList').data('system'),
                n: i,
                ec : $('#user_ec').val()
            });
        };
        var pop = $('#popupProgressCircle');
        var okButton = pop.find('#progressOkButton');
        var errorText = pop.find('#progressErrorText');
        var noteText = pop.find('#progressNoteText');
        var retryButton = pop.find('#progressRetryButton');
        var messageContent = pop.find('.primary-message');
        messageContent.find('p').text(messageContent.data('original'));
        okButton.hide();
        errorText.hide();
        noteText.hide();
        retryButton.hide();
        retryButton.off('click');
        retryButton.on('click', function(e) {
            Popup.close(pop, User.saveChanges);
        });
        setProgress(startFrom, packets.length);
        if ( packets.length > 0 ) {
            if ( typeof suppressPreloader === 'undefined' ) {
                suppressPreloader = 0;
            }
            Popup.show(pop);
            sender(startFrom);
        }
    },
    deleteAccount: function(e) {
        var systems = $('.systemItem');
        var confirmButton = $('.confirmAccountDeletion');
        var backButton = $('[data-handlers="back"]');
        var backUrl = backButton.attr('href');
        confirmButton.attr('disabled','disabled');
        backButton.attr('disabled', 'disabled');
        backButton.attr('href', '#');
        var handleSystem = function(n) {
            var currentSystem = $(systems[n]);
            $('html, body').animate({
                scrollTop: currentSystem.offset().top - 100
            }, 250);
            var miniLoader = currentSystem.find('.mini-loader');
            miniLoader.css('visibility', 'visible');
            var actionData = currentSystem.find('.toggleItem--toggled').data('toggled-value');
            var url = currentSystem.data('transfer');
            if ( actionData === 'del' ) {
                url = currentSystem.data('delete');
                ajaxGet(url,
                    function(data) { // Good
                        handleResponse({
                            response: data,
                            good: true,
                            system: currentSystem,
                            loader: miniLoader,
                            counter: n
                        });
                    },
                    function(data) { // Bad
                        handleResponse({
                            response: data,
                            good: false,
                            system: currentSystem,
                            loader: miniLoader,
                            counter: n
                        });
                    } 
                );
            } else {
                var requestData = {
                    _token : $('input[name="_token"]').val(),
                    transfer_user_select : actionData
                };
                ajaxPostWithData(url,
                    function(data) { // Good
                        handleResponse({
                            response: data,
                            good: true,
                            system: currentSystem,
                            loader: miniLoader,
                            counter: n
                        });
                    },
                    function(data) { // Bad
                        handleResponse({
                            response: data,
                            good: data.status === 200,
                            system: currentSystem,
                            loader: miniLoader,
                            counter: n
                        });
                    },
                    requestData
                );
            }
        };
        var handleResponse = function(data) {
            if ( data.good ) {
                data.loader.css('visibility', 'hidden');

                data.counter++;
                if (data.counter < systems.length ) {
                    handleSystem(data.counter);
                } else { // Susitvarkyta su visomis sistemomis. galima trinti.
                    var url = confirmButton.data('action');
                    suppressPreloader = 0;
                    window.scrollTo(0, 0);
                    GeneralUI.showPreloader(true);
                    ajaxGet(url + '?a=0', 
                        function() {
                            window.location.href = '/';
                        },
                        function() {
                            confirmButton.removeAttr('disabled');
                            backButton.removeAttr('disabled');
                            backButton.attr('href', backUrl);
                            suppressPreloader--;
                        }
                    );
                }
            } else {
                confirmButton.removeAttr('disabled');
                backButton.removeAttr('disabled');
                backButton.attr('href', backUrl);
                suppressPreloader--;
                data.loader.css('visibility', 'hidden');                
            }
        };
        if ( typeof suppressPreloader !== 'undefined' ) {
            suppressPreloader++;
        } else {
            suppressPreloader = 1;
        }
        handleSystem(0);
    },
    handleInstallerPopup: function($this) {
        var that = $this;
        var installerId = that.data('installer-id');
        var deleteAction = that.data('delete-action');
        var installerBox = $('#installersBox');
        var listboxItems = installerBox.find('.listbox--item');
        var installerSelectedButton = $('#installerSelectedButton');
        $.each(listboxItems, function(i, oItem) {
            var item = $(oItem);
            if ( item.data('value') === installerId ) {
                item.hide();
            } else {
                item.show();
            }
        });
        // Reset selection
        installerBox.data('selected-value', '*');
        installerBox.data('selected-index', '*');
        ListboxHandler.select(installerBox);
        // end reset
        installerSelectedButton.off('click');
        installerSelectedButton.on('click', function() {
            var valueSelected = installerBox.data('selected-value');
            if ( valueSelected === '*' ) {
                document.location.href = deleteAction;
            } else {
                document.location.href = deleteAction + '?replace=' + valueSelected;
            }
        });
    }
};

var Toggler = {
    init: function() {
        var toggleGroups = $('.toggleGroup');
        $.each(toggleGroups, function(i, iItem) {
            var $item = $(iItem);
            var toggleItems = $item.find('.toggleItem');
            toggleItems.on('click', function(e) {
                Toggler.toggleItem(e, $(this), $item);
            });
        });
    },
    toggleItem: function(e, $this, $group) {
        var toggled = $group.find('.toggleItem--toggled');
        toggled.removeClass('toggleItem--toggled');
        
        $this.addClass('toggleItem--toggled');
    }
};

// Google Signin
var GG = {
    gUser : {},
    init: function() {
        if ( typeof googleInit === 'undefined' ) return;
        
        if ( Mobile.isMobile() && (typeof isAndroidApp !== 'undefined' || typeof isIosApp !== 'undefined')) {
            var googleButton = $('.googleButton');
            googleButton.attr('href', googleButton.data('action'));
        } else { 
            gapi.load('auth2', function(){
                var gguid = $('#gguid');
                gapi.auth2.init({
                    client_id: gguid.val(),
                    cookiepolicy: 'single_host_origin'
                }).then(function() {   
                    googleAuth = gapi.auth2.getAuthInstance();
                    var loginButton = document.getElementById('googleLogin');
                    if ( loginButton !== undefined && loginButton !== null ) {
                        GG.attachSignin(loginButton);
                    }

                    var linkButton = $('#googleLink');
                    if ( linkButton.length === 0 ) {
                        return;
                    }

                    var isNotLinked = linkButton.data('isNotLinked');
                    if ( isNotLinked !== undefined ) {
                        linkButton.attr('href', '#');
                        GG.attachSignin(linkButton[0]);
                    }
                });
            });
        }
    },
    attachSignin: function(elem) {
        googleAuth.attachClickHandler(elem, {},
            function(googleUser) {
                GG.gUser = googleUser;
                var authResponse = googleUser.getAuthResponse(true);
                document.location.href = '/check/google?id_token=' + GG.gUser.getAuthResponse().id_token + '&at=' + authResponse.access_token;
            }, function(error) {
                console.log(JSON.stringify(error, undefined, 2));
        });
    },
    signedIn: function(onYes, onNo) {
        if ( Mobile.isMobile() && (typeof isAndroidApp !== 'undefined' || typeof isIosApp !== 'undefined') ) {
            onNo(); // Native appsai patys tures pasitikrinti ar vartotojas prisijunges, nes is JS neleidziama.
            return;
        }
        gapi.load('auth2', function() {
            var gguid = $('#gguid');
            gapi.auth2.init({client_id: gguid.val()}).then(function(){
                googleAuth = gapi.auth2.getAuthInstance();
                var result = (googleAuth !== undefined) && googleAuth.isSignedIn.get();
                if ( result ) {
                    onYes();
                } else {
                    onNo();
                }
            },
            function() { // On error
                onNo();
            });
        });
    },
    logOut: function(href) {        
        googleAuth.signOut().then(function () {
            document.location.href = href;
        });
    }
}

var ListboxHandler = {
    init : function() {
        var listboxes = $('.listbox');
        $.each(listboxes, function(iIndex, iListbox) {
            var $iListbox = $(iListbox);
            $iListbox.find('.listbox--item').on('click', function() {
                ListboxHandler.itemSelected($(this), $iListbox);
            });
            ListboxHandler.select($iListbox);
        });
    },
    itemSelected: function($this, $parentListbox) {
        var selectedItem = $parentListbox.find('.listbox--item__selected');
        var thisIndex = $this.data('index');
        var thisValue = $this.data('value');
        
        selectedItem.removeClass('listbox--item__selected');
        $this.addClass('listbox--item__selected');
        
        $parentListbox.data('selected-value', thisValue);
        $parentListbox.data('selected-index', thisIndex);
    },
    select: function($listbox) {
        var selectedItem = $listbox.find('.listbox--item__selected');
        if ( selectedItem.length > 0 ) { selectedItem.removeClass('listbox--item__selected'); }
        var selectedIndex = $listbox.data('selected-index');
        var selectedValue = $listbox.data('selected-value');
        var items = $listbox.find('.listbox--item');
        
        $.each(items, function(iIndexItem, iItem) {
            var $iItem = $(iItem);
            var itemIndex = $iItem.data('index');
            var itemValue = $iItem.data('value');

            if ( selectedIndex !== undefined && selectedIndex !== '*' && itemIndex !== undefined && itemIndex == selectedIndex ) {
                $iItem.addClass('listbox--item__selected');
                $listbox.data('selected-value', itemValue);
            } else if ( selectedValue !== undefined && selectedValue !== '*' && itemValue !== undefined && itemValue == selectedValue ) {
                $iItem.addClass('listbox--item__selected');
                $listbox.data('selected-index', itemIndex);
            }
        });
    }
};

function showNotificationHandler() {
    var showNotificationLink = $('[data-handlers="showNotification"]');

    showNotificationLink.on('click', function (e) {
        e.preventDefault();
        showNotification();
    });
}

function showKeypad($this) {
    var hasAreaControlledByPgm = $this.data('hasAreaAsPgm');
    var popup = $('#keypadPopup');
    var pinField = popup.find('input[name="pin"]');
    var keyList = popup.find('#keyList');
    pinField.val('');
    
    pinField.removeAttr('readonly');
    if ( hasAreaControlledByPgm !== undefined && hasAreaControlledByPgm ) {
        popup.find('#header-pin').hide();
        popup.find('#header-pass').show();
        pinField.removeAttr('pattern');
        keyList.hide();
    } else {
        popup.find('#header-pin').show();
        popup.find('#header-pass').hide();
        pinField.attr('pattern', "[0-9]*");
        pinField.val('');
        keyList.show();
        if ($(pinField).data('mobile') === 1) {
            pinField.attr('readonly', 'readonly');
        } else {
            setTimeout(function() {
                $('#pin').focus();
            }, 500);
        }
    }
}

function pauseRefresh() {
    if ( Mobile.isMobile() ) {
        var c = $('.mobile .content');
        if ( c.length > 0 ) {
            var oXpull = c.data('plugin_xpull');
            if ( oXpull !== undefined ) {
                oXpull.options.paused = true;
            }
        }
    }
}

function resumeRefresh() {
    if ( Mobile.isMobile() ) {
        var c = $('.mobile .content');
        if ( c.length > 0 ) {
            var oXpull = c.data('plugin_xpull');
            if ( oXpull !== undefined ) {
                oXpull.options.paused = false;
            }
        }
    }
}

/**
 * Patikrina ar tekstas yra e-mail tipo.
 * @param {String} input
 * @returns {Boolean}
 */
function isEmail(input) {
    var emailExpr = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if( input === '' || !emailExpr.test(input) ) {
        return false;
    }
    
    return true;
}

/**
 * Interpretuoja skaičių arba tekstą ir gražina Boolean.
 * @param {mixed} input 
 * @returns {Boolean}
 */
function toBool(input) {
    if ( (input === 1) || (input === '1') || (input === 'true') || (input === true) ) {
        return true;
    } else {
        return false;
    }
}

$('.reaction__status').on('click', function (e) {
    e.preventDefault();

    var $this = $(this);

    $.get($this.attr('href'), function (data) {
        if (data.active === true) {
            $this.children('.reaction_status--disabled').addClass('hide');
            $this.children('.reaction_status--enabled').removeClass('hide');
        } else {
            $this.children('.reaction_status--disabled').removeClass('hide');
            $this.children('.reaction_status--enabled').addClass('hide');
        }
    });
});

$(document).ajaxStart(function () {
    if ( typeof(suppressPreloader) === 'undefined' || suppressPreloader <= 0 ) {
        GeneralUI.showPreloader();
    }
});

$(document).ajaxComplete(function (event, request, settings) {
    GeneralUI.hidePreloader();
});

$(function () {
    Mobile.iOsHandler();
    GeneralUI.refreshDateHandler();
    // Pull to refresh
    Mobile.pullToRefreshHandler();
    Mobile.refreshButtonHandler();
    function asyncFunction(callback) {
        setTimeout(function () {
            callback();
        }, 1000);
    }

    Pgm.handlePgmTimers();
    
    Pgm.listenToPgmAsAreaCheckboxes();

    // Custom select
    GeneralUI.customSelectHandler();

    Popup.customPopupHandler();

    Popup.systemLoadingPopupHandler();

    // Custom scroll
    GeneralUI.niceScrollHandler();

    // Navbar
    Navbar.handler();

    SideMenu.handler();
    GeneralUI.ajaxSwitcherHandler();

    //home page settings
    Settings.homePageHandler();

    // Checkbox change on slider ON/OFF click
    Checkbox.handler();

    // System picker
    System.pickerHandler();

    // System transfer user selector
    SystemTransfer.transferInputHandler();

    // Outputs
    showNotificationHandler();

    // Test ip com connection
    System.testIpcomConnectionHandler();

    // License popup
    Popup.licenseHandler();

    // Change language in sign up form.
    System.signUpFormLanguageHandler();

    // Area
    Area.menuHandler();

    // Popup
    Popup.popupHandler();

    // Checkbox group
    Checkbox.groupHandler();

    // Tutorial
    Tutorial.handler();

    // Change status sldier
    Area.changeStatusSliderHandler();

    // Datepicker
    DatePicker.handler();

    GeneralUI.keypadHandler();

    // Global settings - View/Edit users
    Settings.globalHandler();

    // Edit system checkboxes
    System.editHandler();
    
    Dashboard.init();
    
    User.init();
    CameraHandler.create();
    CamStreamSwitcher.create();
    
    Toggler.init();
    
    GG.init();
    
    ModifiedCheckHandler.init();
    
    ListboxHandler.init();
});

$(function() {
    var ua = navigator.userAgent;
    if ( !!ua ) {
        var agentIndex = ua.indexOf('Android');
        if (agentIndex !== -1) {
            var androidversion = parseFloat(ua.match(/Android\s+([\d\.]+)/)[1]);
            if (androidversion < 4.4) {
                var icons = $('.menu-icon');
                var parent = $(icons[0]).parent();
                var ph = $(parent).height();
                var pw = $(parent).width();
                $.each(icons, function(index, iItem) {
                    $(iItem).removeClass('menu-icon');
                    $(iItem).addClass('menu-icon-android4');
                    $(iItem).height(ph * 0.5);
                    $(iItem).width(pw * 0.75);
                });
                var areaIcon = $('.area__status-icon');
                areaIcon.height(ph * 0.5);
                areaIcon.width(pw * 0.75);
            } else {
                //console.log('Android version is too new. ' + androidversion);
            }
        } else {
            //console.log('This is not an android.');
        }
    }
});

$(function() {
    var filePickerButton = $('.fileSelectorButton');
    if ( filePickerButton.length === 0 ) {
        return;
    }
    
    $.each(filePickerButton, function(iIndex, iItem) {
        var item = $(iItem);
        var actualFileInput = $('#' + item.data('fileInput'));
        if ( actualFileInput.length === 0 ) return;

        var fileInputOverride = $('#' + item.data('fileInput') + '_override');
        if ( fileInputOverride.length > 0 ) {
            var currentPadding = fileInputOverride.css('padding-left').replace('px','');
            var resultingPadding = parseInt(currentPadding) + item.width();
            fileInputOverride.css('padding-left',  resultingPadding + 5 + 'px');
        }

        item.on('click', function(e) {
            actualFileInput.click();
        });

        actualFileInput.change(function(e) {
            if ( fileInputOverride.length === 0 ) return;

            fileInputOverride.attr('value', e.target.files[0].name);
        });
    });
    
});

$(function() {
    var downloadButton = $('#downloadAccountBtn');
    
    if ( downloadButton.length > 0 ) {
        downloadButton.on('click', function(e) {
            downloadButton.attr('disabled', true);
            downloadButton.text(downloadButton.data('info'));
            downloadButton.attr('title', downloadButton.data('info'));
            downloadButton.removeClass('btn-primary');
            downloadButton.addClass('btn-default');
            
            var url = downloadButton.data('action');
            ajaxGet(url, function(data) {
                downloadButton.removeAttr('disabled');
                downloadButton.removeClass('btn-default');
                downloadButton.addClass('btn-primary');
                downloadButton.text(downloadButton.data('text'));
                downloadButton.attr('title', downloadButton.data('text'));
                suppressPreloader = 1;
                window.location = downloadButton.data('action2');
            }, function(data) {});
        });
    }
});

function setSuppressPreloader() {
    suppressPreloader = 1;
}

function unsetSuppressPreloader() {
    suppressPreloader = 0;
}