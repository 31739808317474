$(function () {

    var paymentCardFormPopup = $('[data-popup-id="paymentCardFormPopup"]');

    if (paymentCardFormPopup.length != 0) {
        var subscriptionPlanInputs = $('.subscription-popup input[name="subscriptionPlan"]');

        var subscriptionButtonPlan = $('[data-subscription-handler="subscriptionPlanResubscribeButton"]');
        subscriptionButtonPlan.on('click', function (event) {
            event.preventDefault();
            var buttonPlanValue = $(this).data('value');
            var buttonPlanName = $(this).data('subscriptionText');
            var buttonUpdateLink = $(this).data('action');

            $('#planNameForPaymentPopup').text(buttonPlanName);
            $('.subscription-popup form').attr('action', buttonUpdateLink);
            subscriptionPlanInputs.val(buttonPlanValue);
        });
    }

    var brainTreeClientTokenField = $('[data-handlers="brainTreeClientToken"]');
    if (brainTreeClientTokenField.length == 0) {
        return;
    }

    var paypalButton = $('#paypal-button');
    var paypalForm = $('#paypal-form');
    // Create a Client component
    braintree.client.create({
        authorization: brainTreeClientTokenField.val()
    }, function (clientErr, clientInstance) {
        // Create PayPal component
        braintree.paypal.create({
            client: clientInstance
        }, function (paypalErr, paypalInstance) {
            paypalButton.bind('click', function (event) {
                event.preventDefault();

                // Tokenize here!
                paypalInstance.tokenize({
                    flow: 'vault' // This enables the Vault flow //@TODO change to checkout
                    // might be useful later
                    // billingAgreementDescription: 'Your agreement description',
                    // locale: 'en_US',
                    // enableShippingAddress: true,
                    // shippingAddressEditable: false,
                    // shippingAddressOverride: {
                    //     recipientName: 'Scruff McGruff',
                    //     line1: '1234 Main St.',
                    //     line2: 'Unit 1',
                    //     city: 'Chicago',
                    //     countryCode: 'US',
                    //     postalCode: '60652',
                    //     state: 'IL',
                    //     phone: '123.456.7890'
                    // }
                }, function (err, tokenizationPayload) {
                    if (err) {
                    } else {
                        paypalForm.find('input[name="payment_method_nonce"]').val(tokenizationPayload.nonce);
                        paypalForm.submit();
                    }
                });
            });
        });
    });


    var form = $('#payment-card-form');
    var submit = $('#payment-card-form-submit');

    braintree.client.create({
        authorization: brainTreeClientTokenField.val()
    }, function (err, clientInstance) {
        if (err) {
            return;
        }

        // Create input fields and add text styles
        braintree.hostedFields.create({
            client: clientInstance,
            styles: {
                'input': {
                    'color': '#282c37',
                    'font-size': '14px',
                    'transition': 'color 0.1s',
                    'line-height': '1'
                },
                // Style the text of an invalid input
                'input.invalid': {
                    'color': '#E53A40'
                },
                // Style the text of an invalid input
                'input.valid': {
                    'color': '#2F8700'
                },
                // placeholder styles need to be individually adjusted
                '::-webkit-input-placeholder': {
                    'color': 'rgba(0,0,0,0.6)'
                },
                ':-moz-placeholder': {
                    'color': 'rgba(0,0,0,0.6)'
                },
                '::-moz-placeholder': {
                    'color': 'rgba(0,0,0,0.6)'
                },
                ':-ms-input-placeholder': {
                    'color': 'rgba(0,0,0,0.6)'
                }

            },
            // Add information for individual fields
            fields: {
                number: {
                    selector: '#card-number',
                    placeholder: $('#card-number').attr('data-placeholder')
                },
                expirationDate: {
                    selector: '#expiration-date',
                    placeholder: $('#expiration-date').attr('data-placeholder')
                },
                cvv: {
                    selector: '#cvv',
                    placeholder: '***'
                }
            }
        }, function (err, hostedFieldsInstance) {

            if (err) {
                return;
            }

            hostedFieldsInstance.on('validityChange', function (event) {
                // Check if all fields are valid, then show submit button
                var formValid = Object.keys(event.fields).every(function (key) {
                    return event.fields[key].isValid;
                });

                if (formValid) {
                    $('#payment-card-form-submit').prop('disabled', false).addClass('show-button');
                } else {
                    $('#payment-card-form-submit').prop('disabled', true).removeClass('show-button');
                }
            });

            hostedFieldsInstance.on('cardTypeChange', function (event) {
                // Change card bg depending on card type
                if (event.cards.length === 1) {
                    $(form).removeClass().addClass(event.cards[0].type);
                    $('#card-image').removeClass().addClass(event.cards[0].type);
                    $('header').addClass('header-slide');

                    // Change the CVV length for AmericanExpress cards
                    if (event.cards[0].code.size === 4) {
                        hostedFieldsInstance.setAttribute({
                            field: 'cvv',
                            attribute: 'placeholder',
                            value: '****'
                        });
                    }
                } else {
                    hostedFieldsInstance.setAttribute({
                        field: 'cvv',
                        attribute: 'placeholder',
                        value: '***'
                    });
                }
            });

            submit.bind('click', function (event) {
                event.preventDefault();

                hostedFieldsInstance.tokenize(function (err, payload) {
                    if (err) {
                        submit.prop('disabled', false);
                    } else {
                        form.find('input[name="payment_method_nonce"]').val(payload.nonce);
                        form.submit();
                    }
                });
            });
        });
    });
});

$(function() {
    if ( typeof showPlanPopup !== 'undefined' ) {
        var requiredPlan = $('[data-value="' + showPlanPopup + '"');
        requiredPlan.click();
    }
});